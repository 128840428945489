import {DialogButton_Cancel, DialogButton_Close, DialogButton_Submit, DialogModule} from "@intuitionrobotics/thunderstorm/frontend";

export function closeButton(label?: string, id?: string) {
    return DialogButton_Close(() => DialogModule.close(id), label)
        .setClassName("clickable")
        .addStyle({padding: "5px 0px", margin: "10px 6px", textAlign: "center", height: "unset"});
}

export function confirmationButtons(onSubmit: () => void, label?: string, onCancel?: () => void, id?: string) {
    const cancel = DialogButton_Cancel(() => {
        DialogModule.close(id);
        if (onCancel)
            onCancel();
    })
        .addStyle({border: "none", cursor: "pointer"});
    const submit = DialogButton_Submit(onSubmit, label)
        .addStyle({border: "none", cursor: "pointer"});
    return [cancel,
        submit];
}

export function confirmationButtonsNoConfig() {
    const cancel = DialogButton_Cancel(DialogModule.close);
    return [cancel];
}
