import * as React from 'react';
import {createContext, ReactNode, useEffect, useState} from 'react';
import * as emotion from 'emotion';
import {BaseComponent, BrowserHistoryModule, OnUnauthenticatedResponse, RoutingModule, StorageKey, Tooltip, WrapperProps} from "@intuitionrobotics/thunderstorm/frontend";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {createTheme} from "@mui/material";
import {_marginBottom, _marginRight} from '@styles/styles';
import {checkPermissions, registerRoutes} from "./routes";
import Loader from "@components/Loader";
import {BadImplementationException} from "@intuitionrobotics/ts-common";
import {RequestKey_ChangedPermissions} from "@modules/PermissionsModule";
import {PermissionAccessLevel_PmConfigs, PermissionKey_PmConfigs} from "@app/ir-q-app-common/types/legacy/sp-permissions";
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import Dialog_Confirmation from "@components/Dialog_Confirmation";
import {AccountModule, LoggedStatus, OnLoginStatusUpdated, StorageKey_UserEmail} from "@intuitionrobotics/user-account/frontend";
import {EnvironmentModule} from '@modules/EnvironmentModule';
import {QueryParam_RedirectUrl} from "@intuitionrobotics/user-account";
import {LoginModule} from "@modules/LoginModule";
import {OnPermissionsChanged} from "@intuitionrobotics/permissions/frontend";
import {MyToaster} from "./renderers/MyToaster";
import {BugReport} from './BugReport';
import {Dialog} from './Dialog';
import {ThemeProvider} from "@mui/material/styles";
import {DataEnvsModule, UiEnvItem} from "@modules/DataEnvsModule";
import {ActivationsModule, RequestKey_DeleteActivations, RequestKey_ListActivations} from "@modules/ActivationsModule";
import {OnPairsRetrieved} from "@modules/FirebaseListenerModule";
import {DataEnvItem, FullUnitConfig} from "@app/ir-q-app-common/types/units";
import DrawerAppBar from "./MenuWithDrawer";
import {SimpleLoader} from "@components/SimpleLoader";

const theme = createTheme({});

export const dropdownClassName = emotion.css({
    position: "relative",
    padding: 6
})

const lastRoute = new StorageKey<{ pathname: string, search?: string }>('lastRoute')

export function getCurrentUserEmail() {
    return StorageKey_UserEmail.get("fe-stub");
}

export const UnitContext: React.Context<{ activatedUnits: Set<string>, pairedUnits: Set<string> }> =
    createContext({activatedUnits: new Set<string>(), pairedUnits: new Set<string>()});

export enum UserGroupAction {
    CREATE,
    DELETE,
    UPDATE

}

export type UserGroupReducerAction = { type: UserGroupAction, payload: DataEnvItem };
export const userGroupReducer = async (action: UserGroupReducerAction, state: DataEnvItem[]): Promise<DataEnvItem[]> => {
    switch (action.type) {
        case UserGroupAction.CREATE:
            const newlyCreated = await DataEnvsModule.createSync(action.payload);
            return [...state, newlyCreated];
        case UserGroupAction.UPDATE:
            const found = state.findIndex(({_id}) => _id === action.payload._id);
            if (found === -1)
                return state;
            await DataEnvsModule.createSync(action.payload);
            state[found] = action.payload;
            return state;
        case UserGroupAction.DELETE:
            await DataEnvsModule.deleteSync(action.payload._id);
            return state.filter(({_id}) => _id !== action.payload._id);
        default:
            return state;
    }
}

type UserGroupsContextType = { userGroups: UiEnvItem[], dispatch: (type: UserGroupAction, payload: UiEnvItem) => Promise<void> }
export const UserGroupsContext: React.Context<UserGroupsContextType> = createContext<UserGroupsContextType>({
    userGroups: [],
    dispatch: async () => {
    }
});

type LoadingContextType = { setLoading: (loading: boolean) => void }
export const LoadingContext = createContext<LoadingContextType>({
    setLoading: () => {
    }
});
const AppProviders = ({children}: { children: ReactNode }) => {
    const [state, setState] = useState<{ userGroups: UiEnvItem[], error: unknown }>({userGroups: [], error: undefined});
    const [loading, setLoading] = useState(false)
    const dispatch = async (type: UserGroupAction, payload: UiEnvItem) => {
        const result = userGroupReducer({type, payload}, state.userGroups);
        try {
            const newState = await result;
            setState({userGroups: newState, error: undefined});
        } catch (err) {
            setState({...state, error: err});
        }
    };

    useEffect(() => {
        (async () => {
            const dataEnvs: UiEnvItem[] = await DataEnvsModule.querySync();
            setState({userGroups: dataEnvs, error: undefined});
        })().catch(console.error)
    }, []);

    return <UserGroupsContext.Provider value={{userGroups: state.userGroups, dispatch}}>
        <LoadingContext.Provider value={{setLoading}}>
            {children}
            {loading && <SimpleLoader overlay={true}/>}
        </LoadingContext.Provider>
    </UserGroupsContext.Provider>
}

export class App
    extends BaseComponent<WrapperProps, { showDropdown: boolean, activatedUnits: Set<string>, pairedUnits: Set<string> }>
    implements OnUnauthenticatedResponse, OnLoginStatusUpdated, OnRequestListener, OnPermissionsChanged, OnPairsRetrieved {

    private Key_ActivationIds = "activationIds";
    private Key_PairIds = "pairIds";

    constructor(p: WrapperProps) {
        super(p);
        this.logInfo(`App started, version: ${process.env.appVersion}`);

        const cachedActivationIds = localStorage.getItem(this.Key_ActivationIds);
        const cachedPairedIds = localStorage.getItem(this.Key_PairIds);
        const activatedUnits: string[] = cachedActivationIds ? JSON.parse(cachedActivationIds) : [];
        const pairedUnits: string[] = cachedPairedIds ? JSON.parse(cachedPairedIds) : [];
        this.state = {
            showDropdown: true,
            activatedUnits: new Set(activatedUnits),
            pairedUnits: new Set(pairedUnits)
        }
    }

    __onPermissionsChanged = () => this.forceUpdate();

    __onRequestCompleted = (key: string, success: boolean) => {
        if (!success)
            return;

        if (key === RequestKey_ListActivations || key === RequestKey_DeleteActivations) {
            const unitIds = ActivationsModule.getActivatedUnitIds();
            localStorage.setItem(this.Key_ActivationIds, JSON.stringify(unitIds));
            return this.setState({activatedUnits: new Set(unitIds)})
        }

        if (key !== RequestKey_ChangedPermissions)
            return;

        if (checkPermissions(PermissionAccessLevel_PmConfigs, PermissionAccessLevel_PmConfigs.Read, PermissionKey_PmConfigs)())
            PackageManagerModule.fetchProducts();

        this.forceUpdate();
    }

    __onPairsRetrieved = (unitConfigs: FullUnitConfig[]) => {
        const unitIds = unitConfigs.map(u => u.unitId);
        localStorage.setItem(this.Key_PairIds, JSON.stringify(unitIds));
        this.setState({pairedUnits: new Set(unitIds.filter(u => !this.state.activatedUnits.has(u)))})
    }

    componentDidMount() {
        BrowserHistoryModule.getHistory().listen(location => {
            lastRoute.set(location);
            if (location.pathname === "/")
                return this.setState({showDropdown: false});

            this.setState({showDropdown: true})
        })

        const currentLocation = BrowserHistoryModule.getCurrent();
        if (currentLocation.pathname === "/") {
            const oldLocation = lastRoute.get();
            if (oldLocation)
                return BrowserHistoryModule.push(oldLocation);
        }
        lastRoute.set(currentLocation)
    }

    onLoginStatusUpdated = () => {
        if (AccountModule.getLoggedStatus() === LoggedStatus.LOGGED_IN) {
            LoginModule.onLogin();
        }

        this.forceUpdate();
    };

    onUnauthenticatedResponse = () => {
        AccountModule.logout();
    };

    public static dropBlocker<T>(ev: React.DragEvent<T>) {
        ev.preventDefault();
        ev.stopPropagation();
    };

    render() {
        const status = AccountModule.getLoggedStatus();

        switch (status) {
            case LoggedStatus.LOGGED_IN:
                return <AppProviders>
                    <UnitContext.Provider value={{activatedUnits: this.state.activatedUnits, pairedUnits: this.state.pairedUnits}}>
                        {this.renderApp()}
                    </UnitContext.Provider>
                </AppProviders>

            case LoggedStatus.LOGGED_OUT:
                return this.renderRedirect();

            case LoggedStatus.VALIDATING:
                return this.renderValidating();

            default:
                throw new BadImplementationException(`logged status can only be one of 'LOGGED_IN', 'LOGGED_OUT' or 'VALIDATING'`);
        }
    }

    renderRedirect = () => {
        const encodedOrigin = encodeURIComponent(EnvironmentModule.getOrigin());
        window.location.href = `${EnvironmentModule.getKasperoFeUrl()}?${QueryParam_RedirectUrl}=${encodedOrigin}`;
        return null;
    };

    private renderApp() {
        registerRoutes();

        return <ThemeProvider theme={theme}>
            <div className={`match_width match_height`}>
                {this.getApp()}
                {this.renderVersionApp()}
                <Dialog_Confirmation/>
                <MyToaster/>
                <Dialog/>
                <Tooltip/>
                <BugReport/>
            </div>
        </ThemeProvider>
    }

    private getApp = () => <DrawerAppBar
        showUnitSelection={this.state.showDropdown}
    >
        {RoutingModule.getRoutesMap()}
    </DrawerAppBar>;

    private renderVersionApp() {
        const versionApp = `${EnvironmentModule.getEnvName()}-${process.env.appEnv}-${process.env.appVersion}`;
        return <div className={`bottom right absolute ${_marginRight(10)} ${_marginBottom(10)}`}>
            {versionApp}
        </div>
    }

    private renderValidating = () => <>
        <Loader/>
        <MyToaster/>
    </>;
}
