import * as React from 'react';
import {BaseComponent, RoutingModule, ToastModule, TS_Input} from '@intuitionrobotics/thunderstorm/frontend';
import {OnRequestListener} from '@intuitionrobotics/thunderstorm';
import {SimpleLoader} from "@components/SimpleLoader";
import {arrayToMap} from "@intuitionrobotics/ts-common";
import {css} from "emotion";
import {ActivationsModule, RequestKey_DeleteActivations, RequestKey_ListActivations, RequestKey_UpdateActivations} from "@modules/ActivationsModule";
import {PackageManagerModule, RequestKey_FetchAliases, RequestKey_FetchUnits} from "@modules/package-manager/PackageManagerModule";
import {ContactListModule, OnContactsUpdated} from "@modules/ContactListModule";
import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {DataEnvsModule} from "@modules/DataEnvsModule";
import {DefaultApiDefs} from "@intuitionrobotics/db-api-generator/shared/types";
import {OnMetadataUpdated, RequestKey_GetUnitStatusTable, UnitsModule} from "@modules/UnitsModule";
import {CONST_KS_SubUserGroup, CONST_KS_UserGroup} from "@app/ir-q-app-common/types/unit-config";
import {Route_Activation} from "../../routes";
import {OnPairsRetrieved, OnVisibilityChange} from "@modules/FirebaseListenerModule";
import {AuthType, DBActivationsInterface} from '@app/ir-q-app-common/types/activation';
import {CareCenterModule, RequestKey_FetchCCData} from "@modules/CareCenterModule";
import FormControl from '@mui/material/FormControl/FormControl';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import {ActivationTable} from "./ActivationTable";
import {UnitContext} from "../../App";
import {RequestKey_UnpairUnit} from "@modules/KasperModule";

type state<T> = {
    filter: string
    dropdownFilter: DropdownFilters
    loading: boolean
    activationHistoryList: DBActivationsInterface[]
}
const inputStyle = css({
    borderRadius: 5,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    letterSpacing: "0em",
    paddingLeft: "5px"
});

export const createNewBtnStyle = css({
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "center",
    background: "#487CFF",
    color: "white",
    height: "40px",
    width: "160px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    marginRight: 30
});

export type ActivationRow = {
    created: number,
    auditBy: string,
    firstName: string,
    lastName?: string,
    unitId: string,
    som_serial?: string,
    tablet_serial?: string,
    replacement_tablet_serial?: string | null,
    authType: AuthType,
    actions: undefined,
    pincode?: string,
    activated?: boolean,
    contactUpdatedAfterUnitDelete?: boolean
}

enum DropdownFilters {
    USER_AND_UNIT = "User + Unit",
    USER_ONLY = "User Only",
    DELETED_UNITS = "Deleted Units"
}

export class Page_ActivationsList
    extends BaseComponent<{}, state<any>>
    implements OnRequestListener, OnContactsUpdated, OnMetadataUpdated, OnVisibilityChange, OnPairsRetrieved {
    private callingDeletedListApi: boolean = false;

    __onVisibilityChange(visibilityState: DocumentVisibilityState) {
        if (visibilityState === "visible") {
            ActivationsModule.list();
            ActivationsModule.startAutomaticRefresh()
        } else
            ActivationsModule.stopAutomaticRefresh()
    }

    componentWillUnmount() {
        ActivationsModule.stopAutomaticRefresh()
    }

    __onRequestCompleted(key: string, success: boolean, requestData?: string): void {
        if ([DataEnvsModule.getRequestKey(DefaultApiDefs.Create), DataEnvsModule.getRequestKey(DefaultApiDefs.Query)].includes(key) && success)
            return this.setState({loading: false});

        switch (key) {
            case RequestKey_UpdateActivations:
            case RequestKey_GetUnitStatusTable:
            case RequestKey_DeleteActivations:
            case RequestKey_ListActivations:
            case RequestKey_FetchAliases:
            case RequestKey_FetchUnits:
            case RequestKey_FetchCCData:
                this.setState({loading: false})
        }

        if (key === RequestKey_DeleteActivations || key === RequestKey_UnpairUnit || key === RequestKey_ListActivations)
            this.queryMetadata();
    }

    __onPairsRetrieved = () => {
        this.queryMetadata();
    }

    __onContactsUpdated() {
        this.forceUpdate();
    }

    __onMetadataUpdated() {
        this.forceUpdate();
    }

    componentDidMount() {
        this.queryMetadata();
        DataEnvsModule.query();
        ActivationsModule.startAutomaticRefresh();
    }

    queryMetadata = () => {
        UnitsModule.queryUnitMetadata({dataKey: {$in: [CONST_KS_UserGroup, CONST_KS_SubUserGroup]}});
        ContactListModule.query({contactType: "agentUser"});
        PackageManagerModule.fetchAliases();
        PackageManagerModule.fetchUnits();
        CareCenterModule.fetchCCData();
    }

    constructor(props: {}) {
        super(props);
        this.state = {
            filter: "",
            dropdownFilter: DropdownFilters.USER_AND_UNIT,
            loading: false,
            activationHistoryList: []
        };
    }

    render() {
        return <UnitContext.Consumer>
            {units => {
                const {activatedUnits, pairedUnits} = units;
                if (!activatedUnits.size || !pairedUnits.size || !ContactListModule.getItems().length)
                    return <SimpleLoader overlay={true}/>

                const data = this.calculateDevicesThatAreNotUsed(activatedUnits);

                return <div className={"overflow-auto flex-column"}>
                    {this.renderSearchAndFilterAndCreateNewBtn(data)}
                    <ActivationTable isHistory={this.state.dropdownFilter === DropdownFilters.DELETED_UNITS} data={data} loading={this.state.loading}/>
                </div>
            }}
        </UnitContext.Consumer>;
    }

    private renderCreateNewBtn = () => {
        return <button className={createNewBtnStyle} onClick={() => {
            RoutingModule.goToRoute(Route_Activation)
        }}>+ Create new</button>;
    }

    private renderSearchAndFilterAndCreateNewBtn(data: ActivationRow[]) {
        return <div
            className={'ll_h_c match_width'}
            style={{justifyContent: "space-between", padding: 15}}
        >
            <div className={"activation-list-header-left"}>
                <TS_Input
                    value={this.state.filter}
                    onChange={value => this.setState({filter: value})}
                    type={"text"}
                    id={"filter"}
                    placeholder={"Search"}
                    className={`${inputStyle} activation-list-search`}
                />
                {this.renderFilterDropdown()}
                {data.length + " activations"}
            </div>
            {this.renderCreateNewBtn()}
        </div>;
    }

    private renderFilterDropdown() {
        return <FormControl>
            <Select
                className={"activation-list-filter"}
                style={{color: "#636363"}}
                id="dropdown-filter"
                value={this.state.dropdownFilter}
                onChange={(event) => this.setState({dropdownFilter: event.target.value as DropdownFilters})}
            >
                <MenuItem value={"User + Unit"}>User + Unit</MenuItem>
                <MenuItem value={"User Only"}>User Only</MenuItem>
                <MenuItem value={"Deleted Units"}>Deleted Units</MenuItem>
            </Select>
        </FormControl>;
    }

    private prepareData = (activatedUnits: Set<string>) => {
        const contacts: DB_Contact<"agentUser">[] = ContactListModule.getItems();

        switch (this.state.dropdownFilter) {
            case DropdownFilters.USER_AND_UNIT:
                return this.prepareActivations(activatedUnits, contacts);
            case DropdownFilters.USER_ONLY:
                return this.prepareContacts(contacts);
            case DropdownFilters.DELETED_UNITS:
                if (this.state.activationHistoryList.length)
                    return this.prepareActivationsHistory(this.state.activationHistoryList, contacts);

                if (this.callingDeletedListApi)
                    return [];

                this.callingDeletedListApi = true;
                ActivationsModule
                    .queryDeletedActivationList()
                    .then(activationHistoryList => {
                        this.setState({activationHistoryList, loading: false})
                    })
                    .catch(e => {
                        console.error(e);
                        ToastModule.toastError("Failed to get deleted activation list, please try refresh the page")
                    });

                this.setState({loading: true});
                return [];
        }
    }

    private prepareContacts(contacts: DB_Contact<"agentUser">[]) {
        return contacts.reduce((carry: ActivationRow[], contact) => {
            if (contact.active === false) {
                carry.push({
                    created: contact._created.auditAt.timestamp,
                    auditBy: contact._created.auditBy,
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    unitId: contact.contactData.unitId,
                    authType: contact.contactData.authType as AuthType,
                    actions: undefined
                })
            }
            return carry;
        }, []);
    }

    private prepareActivations(activatedUnits: Set<string>, contacts: DB_Contact<"agentUser">[]) {
        const contactMap = arrayToMap(contacts, c => c.contactData.unitId)

        return Array.from(activatedUnits).reduce((carry: ActivationRow[], _id) => {
            const activation = ActivationsModule.getActivation(_id);
            if (!activation)
                return carry;

            const agentUserContact = contactMap[_id];
            if (!agentUserContact)
                return carry;

            carry.push({
                created: activation.registration_date as number,
                auditBy: activation.blame,
                firstName: agentUserContact.firstName,
                lastName: agentUserContact.lastName,
                unitId: _id,
                som_serial: activation.som_device_id,
                tablet_serial: activation.tablet_device_id,
                replacement_tablet_serial: activation.replacement_tablet_device_id,
                authType: activation.auth_type,
                actions: undefined,
                pincode: activation.pincode,
                activated: activation.activated
            })

            return carry;
        }, []);
    }

    private prepareActivationsHistory(activationHistoryList: DBActivationsInterface[], contacts: DB_Contact<"agentUser">[]) {
        const contactMap = arrayToMap(contacts, c => c.contactData.unitId)

        return activationHistoryList.reduce((carry: ActivationRow[], activationHistory) => {
            const agentUserContact = contactMap[activationHistory.agent_id];
            if (!agentUserContact)
                return carry;

            // @ts-ignore
            const deleteDate = activationHistory.modification_date;

            carry.push({
                created: deleteDate,
                auditBy: activationHistory.blame,
                firstName: agentUserContact?.firstName || "",
                lastName: agentUserContact?.lastName || "",
                unitId: activationHistory.agent_id,
                som_serial: activationHistory.som_device_id,
                tablet_serial: activationHistory.tablet_device_id,
                replacement_tablet_serial: activationHistory.replacement_tablet_device_id,
                authType: activationHistory.auth_type,
                actions: undefined,
                pincode: activationHistory.pincode,
                activated: activationHistory.activated,
                contactUpdatedAfterUnitDelete: deleteDate && agentUserContact._updated.auditAt.timestamp > new Date(deleteDate).getTime()
            })

            return carry;
        }, []);
    }

    calculateDevicesThatAreNotUsed = (activatedUnits: Set<string>): ActivationRow[] => {
        const data: ActivationRow[] = this.prepareData(activatedUnits);

        return data.filter(id => !this.isFilteredOut(id)).sort((d1, d2) => {
            const d1T = d1.created;
            const d2T = d2.created;
            if (!d2T)
                return 1;
            if (!d1T)
                return -1;

            return d1T < d2T ? 1 : -1
        })
    };

    private isFilteredOut(ret: ActivationRow): boolean {
        const filter = this.state.filter.trim();
        if (!filter)
            return false;

        const filters = filter.split(" ");
        for (const aFilter of filters) {
            const aFilterElements = aFilter.split(":");
            if (aFilterElements.length === 2 && aFilterElements[0] in ret) {
                const string = aFilterElements[0] as keyof ActivationRow;
                const retElement = ret[string];
                const toMatch = aFilterElements[1].toLowerCase();
                if (retElement !== undefined && toMatch && !`${retElement}`.toLowerCase().includes(toMatch))
                    return true;

            } else {
                if (!this.someValuesMatch(ret, aFilter))
                    return true;
            }
        }
        return false;
    }

    private someValuesMatch(ret: ActivationRow, aFilter: string) {
        return Object.values(ret).some(v => `${v}`.toLowerCase().includes(aFilter.toLowerCase()));
    }

}
