import {filterDuplicates, Module} from "@intuitionrobotics/ts-common";
import {Unit, UnitBasic} from "@app/ir-q-app-common/types/units";
import {UnitsModule} from "@modules/UnitsModule";
import {ActivationsModule} from "@modules/ActivationsModule";

class UnitsManagerModule_Class
    extends Module {

    private treatItAs: { [unitId: string]: { pairing?: boolean, activation?: boolean } } = {"": {}};

    setTreatItAs(unitId: string, pairing: boolean, activation: boolean) {
        this.treatItAs = {[unitId]: {pairing, activation}};
    }

    getUnitBasic = (unitId: string): UnitBasic | undefined => {
        const activation = ActivationsModule.getActivation(unitId);
        const pair = UnitsModule.getIdentity(unitId);
        if (!activation && !pair)
            return;

        const somSerial = activation?.som_device_id || pair?.identities.find(i => i.type === "som")?.serial;
        const tabletSerial = activation?.tablet_device_id || pair?.identities.find(i => i.type === "tablet")?.serial;
        if (!somSerial || !tabletSerial)
            return;

        return {
            unitId,
            somSerial,
            tabletSerial,
            activation: !!activation,
            pair: !!pair
        }
    }

    isUnitActivation(unitId: string) {
        return !!ActivationsModule.getActivation(unitId);
    }

    getDeviceType = (deviceId: string, unitList: string[]) => {
        return this.getDeviceAndUnit(deviceId, unitList)?.deviceType;
    };

    getDeviceAndUnit = (deviceId: string, unitList: string[]): { deviceType: string, unitId: string } | undefined => {
        for (const unitId of unitList) {
            const activation = ActivationsModule.getActivation(unitId);
            const pair = UnitsModule.getIdentity(unitId);
            if (!activation && !pair)
                continue;

            const somIdentity = pair?.identities.find(i => i.type === "som");
            const somIds = [activation?.som_device_id, somIdentity?.serial, somIdentity?.sha256];
            if (somIds.includes(deviceId))
                return {deviceType: "som", unitId};


            const tabletIdentity = pair?.identities.find(i => i.type === "tablet");
            const tabletIds = [activation?.tablet_device_id, tabletIdentity?.serial, tabletIdentity?.sha256];
            if (tabletIds.includes(deviceId))
                return {deviceType: "tablet", unitId};
        }
    };

    getDevicesIdsByUnits(units: Unit[], _devices: string[], giveByShaAndSerial?: boolean) {
        const unitsBasic = units.map(u => UnitsManagerModule.getUnitBasic(u.unitId));
        return filterDuplicates(unitsBasic.reduce((carry: string[], unitBasic, idx) => {
            if (!unitBasic) {
                this.logError("Cannot find identity for unit: " + units[idx].unitId + " product: " + units[idx].product)
                return carry;
            }

            const treatAs = this.treatItAs[unitBasic.unitId];
            if (!treatAs) {
                if (unitBasic.activation) {
                    if (_devices.includes("som"))
                        carry.push(unitBasic.somSerial);

                    if (_devices.includes("tablet"))
                        carry.push(unitBasic.tabletSerial);
                } else {
                    const pair = UnitsModule.getIdentity(unitBasic.unitId);
                    pair && pair.identities.filter(identity => _devices.includes(identity.type)).forEach(i => {
                        carry.push(i.sha256);
                    })
                }
            } else {
                if (treatAs.activation) {
                    if (_devices.includes("som"))
                        carry.push(unitBasic.somSerial);

                    if (_devices.includes("tablet"))
                        carry.push(unitBasic.tabletSerial);
                }
                if (treatAs.pairing) {
                    const pair = UnitsModule.getIdentity(unitBasic.unitId);
                    pair && pair.identities.filter(identity => _devices.includes(identity.type)).forEach(i => {
                        carry.push(i.sha256);
                    })
                }
            }

            return carry;
        }, []));
    }

    is3Point0Unit = (unitId: string) => {
        const basic = UnitsManagerModule.getUnitBasic(unitId);
        if (!basic)
            return null;

        return basic.tabletSerial !== "" && this.is3p0Device(basic.tabletSerial, basic);
    }

    is3p0Device = (serial?: string, basic?: UnitBasic) => {
        if (!basic)
            return !!serial?.startsWith("ELQ30");

        if (!basic.tabletSerial || !basic.somSerial)
            return false;

        return basic.tabletSerial.startsWith("ELQ30") || basic.tabletSerial === basic.somSerial;
    }

    isSerialAtRiskForESD(serial?: string) {
        return serial?.startsWith("220");
    }
}

export const UnitsManagerModule = new UnitsManagerModule_Class("UnitsManagerModule");
