import * as React from "react";
import {CSSProperties} from "react";
import {_keys, compareVersions, formatTimestamp, Minute, sortArray} from "@intuitionrobotics/ts-common";
import {AppPackage_ElliQ, AppPackage_TabletElliQ} from "@app/ir-q-app-common/types/push-messages";
import {paddingBottom, paddingTop} from "@styles/dynamic-styles";
import {DeviceInfo, isAlive} from "@app/ir-q-app-common/types/runtime-status";
import {COLOR_lightGreen, COLOR_lightOrange, COLOR_lightRed} from "@styles/colors";
import {CheckboxOption, ToastModule, TooltipBuilder, TooltipDefaultStyle, TooltipModule, TS_Checkbox} from "@intuitionrobotics/thunderstorm/frontend";
import {css} from "emotion";
import {DeviceType} from "@app/ir-q-app-common/types/units";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";
import {Tooltip} from "@mui/material";
import {UnitsModule} from "@modules/UnitsModule";
import moment = require("moment");

export type CompleteInfo = DeviceInfo & { onboardingTime?: number }
const _isAlive = (timestamp?: number | null) => isAlive(timestamp, 4 * Minute);

export type InfoRenderer = (props: { data?: CompleteInfo, deviceType?: DeviceType, serial?: string, otherData?: CompleteInfo }) => React.ReactNode

type ColorPicker = 0 | 1 | 2;

export const showTooltip = (e: React.MouseEvent, content: React.ReactNode, y: number = e.clientY + 15, _css?: CSSProperties) => {
    new TooltipBuilder(content, e)
        .setStyle({...TooltipDefaultStyle, position: "absolute", zIndex: 9, ..._css})
        .setLocation(e.clientX + 10, y + window.pageYOffset)
        .show();
};

export const hideTooltip = () => TooltipModule.hide();

export const colorPicker = (code: ColorPicker) => {
    switch (code) {
        case 0:
            return COLOR_lightGreen;
        case 1:
            return COLOR_lightOrange;
        case 2:
            return COLOR_lightRed;
    }
};

export const batteryColor = (data?: CompleteInfo) => {
    const battery = data?.battery;
    if (!battery?.percentage || battery?.percentage < 1)
        return 2;
    if (battery?.health !== "Good" || battery?.percentage < 30)
        return 1;
    return 0;
};

const dsp_statusColor = (data?: CompleteInfo): ColorPicker => {
    if (data?.hw_status?.dsp_status?.general_status?.toLowerCase() !== "ok" || data?.hw_status?.dsp_status?.spi_status?.toLowerCase() !== "connected")
        return 1;
    return 0;
};

const mcus_connectivityStatus = (data?: CompleteInfo): ColorPicker => {
    const d = data?.hw_status?.mcus_connectivity;
    if (!d)
        return 1;

    const notAllConnected = d.head?.toLowerCase() !== "connected" || d.leds_mcu?.toLowerCase() !== "connected" || d.motors_mcu?.toLowerCase() !== "connected" || d.neck?.toLowerCase() !== "connected" || d.pan?.toLowerCase() !== "connected";
    // for 3.0 or SO
    const notAllConnectedInUsbRobot = d.head?.toLowerCase() !== "connected" || d.robot_usb_hub?.toLowerCase() !== "connected" || d.robot_mcp?.toLowerCase() !== "connected" || d.host_mcu?.toLowerCase() !== "connected" || d.neck?.toLowerCase() !== "connected" || d.pan?.toLowerCase() !== "connected";

    // if it's connected for one - it means it's good
    if (notAllConnected && notAllConnectedInUsbRobot)
        return 1;
    return 0;
};

const mcus_connectivityTooltip = (data?: CompleteInfo) => {
    const d = data?.hw_status?.mcus_connectivity || {};
    return <div>
        {_keys(d).map(p => <div key={p}
                                style={{backgroundColor: d[p] !== "connected" ? COLOR_lightOrange : "inherit"}}>{p}: {d[p]}</div>)}
    </div>;
};

const correctTemperture = (t: number) => t > 20 && t < 45;

const motor_temperaturesStatus = (data?: CompleteInfo): ColorPicker => {
    const d = data?.hw_status?.motor_temperatures;
    if (!d)
        return 1;
    // @ts-ignore
    if (correctTemperture(+d.head) && correctTemperture(+d.neck) && correctTemperture(+d.pan) && d.temperature_zone === "safe_zone")
        return 0;
    return 1;
};

const motor_temperaturesTooltip = (data?: CompleteInfo) => {
    const d = data?.hw_status?.motor_temperatures || {};
    if (!d)
        return null;

    const color = (p: string) => {
        if (p === "temperature_zone")
            // @ts-ignore
            return d[p] === "safe_zone" ? "inherit" : COLOR_lightOrange;

        // @ts-ignore
        return correctTemperture(+d[p]) ? "inherit" : COLOR_lightOrange;
    };

    return <div>
        {_keys(d).map(p => <div key={p} style={{backgroundColor: color(p)}}>{p}: {d[p]}</div>)}
    </div>;
};

const filterHwVersionKeys = (hwVersionKeys: string[], serial?: string) => {
    return hwVersionKeys.filter(hwVersionKey => {
        return !UnitsManagerModule.is3p0Device(serial) || !["cpld_version", "dsp_version", "dsp_build_info"].includes(hwVersionKey);
    });
}
const hWVersionsTooltip = (data?: CompleteInfo, serial?: string) => <div>
    {data?.hw_versions && filterHwVersionKeys(_keys(data.hw_versions), serial).map(artifact => <div key={artifact} style={{
        backgroundColor: hwSingleVersionStatus(data.hw_versions[artifact]) === 1 ? COLOR_lightOrange : "inherit"
    }}>{artifact}: {data?.hw_versions[artifact]}</div>)}
</div>;

const hwSingleVersionStatus = (artifact: string): ColorPicker => (artifact === "0.0.0.0" || artifact === "NA" || artifact === "N/A") ? 1 : 0;

const hwVersionsStatus = (data?: CompleteInfo, serial?: string): ColorPicker => data?.hw_versions ? sortArray(
    filterHwVersionKeys(_keys(data?.hw_versions), serial).map(artifact => hwSingleVersionStatus(data?.hw_versions[artifact])), num => num)[0] : 0;

export const memoryAndThreadsColor = (data?: CompleteInfo): ColorPicker => data?.memory_usage ? ((data.memory_usage.usedMemory / data.memory_usage.maxMemory >= 0.85 || (data.number_of_active_threads > 350 || (!data.camera_calibration && !data.hw_status && data.number_of_active_threads > 100))) ? 1 : 0) : 0;

export const errorsColor = (data?: CompleteInfo): ColorPicker => data?.plans?.status?.["system_errors"] ? 2 : 0;

export const unitErrorsColor = (data?: CompleteInfo): ColorPicker => data?.error_codes ? 2 : 0;
const wifiTooltip = (data?: CompleteInfo) => {
    if (!data?.wifi)
        return null;

    return _keys(data?.wifi).map(d => {
        if (d === "MAC Address")
            return null;
        return <div key={d}>{d}: {data?.wifi?.[d]}</div>;
    });
};
const getAppShortName = (packageName: string) => {
    const shortName = packageName.substring(packageName.lastIndexOf("_") + 1).toLowerCase();
    if (shortName === "qcall")
        return "QCall";

    if (shortName === "qvision")
        return "QVision";

    if (shortName === "pm")
        return "PM";

    if (shortName === "kaspero")
        return "Kaspero";

    if (shortName === "strickland")
        return "Strickland";

    return shortName.charAt(0).toUpperCase() + shortName.slice(1); // first letter capital
}

function innerNodeClass(checked: boolean) {
    return css({
        width: 15, height: 15, background: checked ? "red" : "green"
    });
}

export const getStatus = (deviceType: DeviceType, unitId: string) => (data?: CompleteInfo) => {
    const options: CheckboxOption<{ label: string, bad: boolean, timestamp?: number }>[] = [];
    if (data && data.last_polling_time)
        Object.keys(data.last_polling_time).forEach(packageName => {
            let shortName = getAppShortName(packageName);
            const versionName = UnitsModule.getRuntimeStatus(unitId)?.som?.version_name;

            if (shortName === "Kaspero" && UnitsManagerModule.isUnitActivation(unitId))
                return;
            if (shortName === "QCall" && versionName && compareVersions(versionName.replace(/-/g, "."), "3.19.6") <= 0)
                return;

            if (shortName === "Elliq" && deviceType === "som") // RS App
                return;

            if (shortName === "Elliq" && deviceType === "tablet") // RS App
                shortName = "RS";

            if (shortName === "Kyou")
                shortName = "Elliq";

            options.push({
                value: {
                    label: shortName,
                    bad: !isAlive(data.last_polling_time[packageName]),
                    timestamp: data.last_polling_time[packageName]
                }, disabled: true
            });
        });

    options.sort((a, b) => {
        if (a.value.label === "Elliq" || a.value.label === "RS") {
            return -1; // 'a' should come before 'b'
        } else if (b.value.label === "Elliq" || b.value.label === "RS") {
            return 1; // 'b' should come before 'a'
        } else {
            return a.value.label.localeCompare(b.value.label); // Sort alphabetically for other elements
        }
    });

    return renderInfo("", <div className={"ll_h_c"}>
        {options.map((option, i: number) => {
                const timestamp = option.value.timestamp;
                const title = timestamp ? new Date(timestamp).toUTCString() : "N/A";
                return <TS_Checkbox<{ label: string, bad: boolean, timestamp?: number }>
                    key={i}
                    value={option.value}
                    checked={!!options.find(o => o.value.label === option.value.label)?.value.bad}
                    onCheck={() => {
                    }}
                    label={option.value.label}
                    disabled={option.disabled}
                    containerClass={() => css({
                        margin: "unset",
                        marginRight: 18
                    })}
                    innerNode={checked => <Tooltip
                        key={`Tooltip-${option.value.label}`}
                        placement={"bottom-end"}
                        enterDelay={0}
                        style={{fontSize: 20}}
                        title={<h3>{title}</h3>}
                    >
                        <div className={innerNodeClass(checked)}/>
                    </Tooltip>}
                />;
            }
        )}
    </div>);
};

export const convertTimeToFormat = (dateString: string | undefined, format: string = "ddd DD MMM, HH:mm") => {
    if (!dateString)
        return "";
    return moment(dateString, "HH:mm:ss_DD-MM-YYYY").format(format);
};
export const getLastPolling = (data?: CompleteInfo, deviceType?: DeviceType) => {
    if (!data || !deviceType)
        return null;

    const appName = deviceType === "som" ? AppPackage_ElliQ : AppPackage_TabletElliQ;
    return data?.last_polling_time?.[appName.replace(/\./g, "_")];
}

const getRenderMemoryAndThreads = (data: CompleteInfo, deviceType: DeviceType, title: string, alive: boolean) => renderInfo(title,
    `${data?.memory_usage?.usedMemory ? `${data?.memory_usage.usedMemory}/${data?.memory_usage.maxMemory} mb` : "N/A"}  -  ${data?.number_of_active_threads}`,
    alive ? colorPicker(memoryAndThreadsColor(data)) : COLOR_lightRed);

const getHWStatus = (data?: CompleteInfo, deviceType?: DeviceType, serial?: string, lastPollingTime?: number | null) => {
    const lastPolling = lastPollingTime || getLastPolling(data, deviceType);
    return data?.hw_status ? renderInfo(
            "Hardware Status",
            <div>
                {!UnitsManagerModule.is3p0Device(serial) && <div style={{backgroundColor: _isAlive(getLastPolling(data, deviceType)) ? colorPicker(dsp_statusColor(data)) : COLOR_lightRed}}>
                    dsp_status: {data?.hw_status.dsp_status.general_status} - {data?.hw_status.dsp_status.spi_status}
                </div>}
                <div
                    style={{backgroundColor: _isAlive(lastPolling) ? colorPicker(mcus_connectivityStatus(data)) : COLOR_lightRed}}><span
                    onMouseMove={(e) => showTooltip(e, mcus_connectivityTooltip(data))}
                    onMouseLeave={hideTooltip}>mcus_connectivity: {mcus_connectivityStatus(
                    data) === 0 ? "All connected" : "Not all connected"}</span></div>
                <div
                    style={{backgroundColor: _isAlive(lastPolling) ? colorPicker(motor_temperaturesStatus(data)) : COLOR_lightRed}}><span
                    onMouseMove={(e) => showTooltip(e, motor_temperaturesTooltip(data))}
                    onMouseLeave={hideTooltip}>motor_temperatures: {motor_temperaturesStatus(
                    data) === 0 ? "All within the norm" : "Not all within the norm"}</span></div>
                <div style={{backgroundColor: _isAlive(lastPolling) ? colorPicker(hwVersionsStatus(data, serial)) : COLOR_lightRed}}><span
                    onMouseMove={(e) => showTooltip(e, hWVersionsTooltip(data, serial))}
                    onMouseLeave={hideTooltip}>hw_versions: {hwVersionsStatus(
                    data, serial) === 0 ? "All as expected" : "Not all as expected"}</span></div>
            </div>)
        : renderInfo("Hardware Versions", <div style={{display: "flex", height: 72}}><span
            style={{margin: "auto 0"}}>{hWVersionsTooltip(data, serial)}</span></div>)
}

//: { [k: string]: InfoRenderer }
export const Renderers_UnitInfo: { [k: string]: InfoRenderer } = {
    VoiceAuthentication: ({data, otherData}) => {
        const voiceAuthenticatorEnrolled = data?.voice_authenticator_enrolled || otherData?.voice_authenticator_enrolled;
        const voiceAuthenticatorEnrolledDate = data?.voice_authenticator_enrolled_date || otherData?.voice_authenticator_enrolled_date;
        const voiceAuthenticatorEnrolledType = data?.voice_authenticator_enrolled_type || otherData?.voice_authenticator_enrolled_type;
        const voiceAuthenticatorMaxAutomaticEnrollingAttemptsReached = data?.voice_authenticator_automatic_enrolling_attempts_reached || otherData?.voice_authenticator_automatic_enrolling_attempts_reached;
        const voiceAuthenticatorEnabled = data?.voice_authenticator_enabled || otherData?.voice_authenticator_enabled;
        const personalizedWakewordEnrolled = data?.personalized_wakeword_enrolled || otherData?.personalized_wakeword_enrolled;
        const personalizedWakewordEnrolledDate = data?.personalized_wakeword_enrolled_date || otherData?.personalized_wakeword_enrolled_date;

        return renderInfo("Voice Configuration",
            `Voice ID Status: ${!!voiceAuthenticatorEnrolled ? `Trained ${voiceAuthenticatorEnrolledDate ? `on ${voiceAuthenticatorEnrolledDate} UTC` : ""} ${!!voiceAuthenticatorEnrolledType ? `(${voiceAuthenticatorEnrolledType.replace("_", " ").toLowerCase()})` : ""}` : (!!voiceAuthenticatorEnabled ? (voiceAuthenticatorMaxAutomaticEnrollingAttemptsReached ? "Stopped training, reached maximum times" : "Training") : "Don't Train")} \n` +
            `Voice ID: ${!!voiceAuthenticatorEnabled ? "Enabled" : "Not Enabled"} \n` +
            `Personalized Wakeword Status: ${!!personalizedWakewordEnrolled ? `Enrolled ${personalizedWakewordEnrolledDate ? `on ${personalizedWakewordEnrolledDate} UTC` : ""}` : "Not Enrolled"}`);
    },
    Serial: ({serial}) => renderInfo("Serial", `${serial} ${UnitsManagerModule.isSerialAtRiskForESD(serial) ? "(ESD Risk)" : ""}`),
    AndroidVersion: ({data}) => renderInfo("Android Version", data?.android_version),
    SpeechServerConnection: ({data, deviceType}) => deviceType === "som" ? renderInfo("Speech Server Connection", data?.speechServer_connection_status || "N/A") : undefined,
    Battery: ({data, deviceType}) => renderInfo("Battery",
        `${data?.battery?.percentage}% - ${data?.battery?.health} - ${data?.battery?.charging_state}`,
        _isAlive(getLastPolling(data, deviceType)) ? colorPicker(batteryColor(data)) : ""),

    CradleState: ({data}) => data?.system_mode ?
        renderInfo("Cradle",
            data?.cradle_state === undefined ? "N/A" : data?.cradle_state ? "In" : "Out") :
        renderInfo("", ""),
    Environment: ({data}) => renderInfo("Environment", data?.environment),
    StricklandState: ({data, deviceType}) => renderInfo('Strickland State', <div
        style={{backgroundColor: _isAlive(getLastPolling(data, deviceType)) ? COLOR_lightGreen : COLOR_lightRed}}>
        {data?.strickland_state || 'N/A'}</div>),
    HWStatus: ({data, deviceType, serial}) => getHWStatus(data, deviceType, serial),
    HWStatusOfOtherData: ({data, deviceType, serial, otherData}) => getHWStatus(otherData, deviceType, serial, getLastPolling(data, "som")),
    Kasper: ({data}) => renderInfo("Kasper", data?.kasper?.version ? `${data?.kasper.version} - ${data?.kasper.identity}` : ""),
    Boot: ({data}) => renderInfo("Last Boot", data?.last_boot?.device ? `Device: ${convertTimeToFormat(data?.last_boot.device)}` : ""),
    Restart: ({data}) => renderInfo("Last Restart", data?.last_restart?.device ? `Device: ${convertTimeToFormat(data?.last_restart.device)}` : ""),
    Onboarding: ({data}) => renderInfo("Onboarding Time", data?.onboardingTime ? `UTC: ${formatTimestamp("HH:mm:ss_DD-MM-YYYY", data.onboardingTime)}` : "N/A"),
    ListeningStatusOrScreenBrightness: ({data}) => data?.listening_status ? renderInfo("Listening Status", data?.listening_status) : renderInfo(
        "Screen Brightness", data?.screen_brightness),
    MacAddresses: ({data}) => renderInfo("MAC",
        data?.mac_addresses?.mac_bt ? `BT: ${data?.mac_addresses.mac_bt} - WiFi ${data?.mac_addresses.mac_wifi}` : ""),
    SpeedTestResult: ({data}) => renderInfo("Speed Test Result",
        data?.last_speed_test),
    MemoryAndThreads: ({data, deviceType, serial, otherData}) => {
        if (!serial || !data || !deviceType || !otherData)
            return;

        const alive = _isAlive(getLastPolling(data, deviceType));
        const is3p0 = UnitsManagerModule.is3p0Device(serial);
        // We assume that if 3p0 then we are displaying the RS App so we add the elliq side
        if (is3p0)
            return <>
                {getRenderMemoryAndThreads(data, deviceType, "Memory and Threads number - RS App", alive)}
                {getRenderMemoryAndThreads(otherData, deviceType, "Memory and Threads number - Elliq App", alive)}
            </>

        return getRenderMemoryAndThreads(data, deviceType, "Memory and Threads number", alive)
    },
    SystemErrors: ({data, deviceType}) => data?.plans ?
        renderInfo("System Errors", data?.plans?.status?.["system_errors"] ?
                <div>
                    {_keys(data?.plans?.status?.["system_errors"]).map((d) => {
                        if (d !== "timestamp")
                            return <div key={d}>{d}: {data?.plans?.status["system_errors"][d]}</div>;
                        return null;
                    })}
                </div> : <div style={{display: "flex", height: 54}}><span style={{margin: "auto 0"}}>No errors</span></div>,
            !_isAlive(getLastPolling(data, deviceType)) ? COLOR_lightRed : colorPicker(errorsColor(data))) :
        renderInfo("", <div style={{height: 54}}/>),
    UnitErrorCodes: ({data, deviceType, serial, otherData}) => {
        let deviceData: CompleteInfo | undefined = Object.assign({}, data);
        const is3p0 = UnitsManagerModule.is3p0Device(serial);
        // The errors written in Elliq app side so if 3p0 then we display the errors from Elliq App
        if (is3p0)
            deviceData = Object.assign({}, otherData);

        return renderInfo("Unit error codes", <div>
            <div>Current error codes: {deviceData?.error_codes ? deviceData?.error_codes : "No errors"}</div>
            <div>previous error codes: {deviceData?.error_codes_previous ? deviceData?.error_codes_previous : "No errors"}</div>
            <div>Latest error: {deviceData?.error_code_latest_errors_timestamp}</div>
            <div>Latest clear: {deviceData?.error_code_latest_clear_timestamp}</div>
        </div>, colorPicker(unitErrorsColor(deviceData)))
    },
    Timestamp: ({data}) => {
        return renderInfo("Timestamp",
            `UTC: ${convertTimeToFormat(data?.timestamp?.utc)} \n` +
            `Device: ${convertTimeToFormat(data?.timestamp?.device)} \n` +
            `Israel: ${convertTimeToFormat(data?.timestamp?.israel)} \n` +
            `Timezone: ${data?.timestamp?.timezone}`)
    },
    Version: ({data}) => renderInfo("Version", `${data?.version_name} (${data?.version_code})`),
    OriginalVersion: ({data}) => renderInfo("Original Version", data?.unit_config?._originalVersion + "\n" + `(Content Version: ${data?.unit_config?.contentVersion})`),
    Wifi: ({data}) => renderInfo("Wifi", <div><span
        onMouseMove={(e) => showTooltip(e, wifiTooltip(data), e.clientY - 175)}
        onMouseLeave={hideTooltip}>Signal Strength: {data?.wifi && data?.wifi["Signal Strength"]} - (link speed: {data?.wifi && data?.wifi["link speed"]} Mbps)</span>
    </div>),
    TimesTitle: () => renderTitle("Times:"),
    VersionsTitle: () => renderTitle("Versions:"),
    OtherTitle: () => renderTitle("Other:"),
    ConnectivityTitle: () => renderTitle("Connectivity:"),
    ElliqAppTitle: () => renderTitle("Elliq App:")
};

export const getShortEnvName = (env: string) => {
    if (!env)
        return env;

    if (typeof env !== "string") {
        console.error("env is not a string", env, "url is " + window.location.href)
        ToastModule.toastError("Something went wrong, please open a bug and include logs");
        return "Dev";
    }

    if (env.toLowerCase() === "development")
        return "Dev";

    if (env.toLowerCase() === "staging")
        return "Stg";

    if (env.toLowerCase() === "production")
        return "Prod";

    return env;
}

export function renderInfo(key: string, value?: React.ReactNode, bgColor?: string) {
    if (!value)
        return;

    return <div key={key} className={`ll_h_c match_width ${paddingTop(4)} ${paddingBottom(4)} `}
                style={{
                    justifyContent: "space-evenly",
                    backgroundColor: bgColor ? bgColor : "inherit",
                    borderBottom: "0.1px solid rgba(204, 204, 204, 0.5)"
                }}>
        <div style={{flex: "0 1 40%", minHeight: !key ? 18 : "unset"}}>{key}</div>
        <div style={{flex: "0 1 60%", whiteSpace: "pre"}}>{value}</div>
    </div>;
}

export function renderTitle(text: string, component: any = null) {
    return (<div key={text} className={`ll_h_c match_width ${paddingTop(5)} ${paddingBottom(3)} `}
                 style={{borderBottom: "1.5px solid rgb(204, 204, 204)", fontWeight: "bold"}}>
        <div>{text}</div>
        {component && <span style={{marginLeft: "10px", marginRight: "10px"}}>-</span>}
        {component && <div style={{marginTop: "3px"}}>{component}</div>}
    </div>);
}
