import * as React from "react";
import {InputTextField} from "@components/InputTextField";
import {InputTextAreaField} from "@components/InputTextAreaField";
import Page_ScanningPage, {Scanning} from "./Page_ScanningPage";
import {DeviceWithSameSerialAlreadyInSystem, PairedDevice, Request_Pair} from "@app-sp/app-shared/units";
import {marginTop} from "@styles/dynamic-styles";
import {Html5QrcodePluginFallback} from "@app/ir-q-app-common/app-frontend/components/Html5QrcodePluginFallback";
import {Button} from "@mui/material";
import {Dialog_Builder, DialogModule, ToastModule, TS_Checkbox} from "@intuitionrobotics/thunderstorm/frontend";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {QueryParam_Product, QueryParam_SomQR, QueryParam_TabletQR, QueryParam_UnitId} from "@consts/common";
import {elliqIdRe} from "@app-sp/app-shared/rejexes";
import {PackageManagerModule} from "@modules/package-manager/PackageManagerModule";
import {KasperModule, OnDeviceWithSameSerialAlreadyInSystem, OnPairUnitFailed, OnUnitIdentityFetched, RequestKey_PairUnit, RequestKey_UnpairUnit} from "@modules/KasperModule";
import {BarcodeV1, UnitIdentity} from "@app/ir-q-app-common/types/units";
import {checkPermissions, Url_SupportSoftwareOnly, Url_UpdateEnv} from "../../routes";
import {PermissionAccessLevel_Units, PermissionKey_Units} from "@app/ir-q-app-common/types/legacy/sp-permissions";
import {LoadableComponent} from "@components/LoadableComponent";
import {closeButton, confirmationButtons} from "@components/AppDialog";
import {PermissionsComponent} from "@intuitionrobotics/permissions/frontend";
import {SetUserGroup} from "../units/components/SetUserGroup";
import {Elliq_ProductKey} from "@app/ir-q-app-common/shared/consts";

const icon__scanQrFromPhone = require("@res/images/icon__scanQrFromPhone.png");
const icon__unpair = require("@res/images/icon__unpair.svg");
const icon__warning = require("@res/images/icon__warning.svg");

type State = Scanning & {
    version?: string
    devUnit?: boolean
    softwareOnly?: boolean
    product: string
    unitId?: string
    somBarcode?: string
    tabletBarcode?: string
    QRCodesByUrlParams?: boolean
    comment?: string
}

class Page_DevicePairing
    extends Page_ScanningPage<{}, State>
    implements OnUnitIdentityFetched, OnRequestListener, OnPairUnitFailed, OnDeviceWithSameSerialAlreadyInSystem {

    constructor(props: {}) {
        super(props);
        const unitId = LoadableComponent.getQueryParameter(QueryParam_UnitId);
        const product = LoadableComponent.getQueryParameter(QueryParam_Product) || Elliq_ProductKey;
        const {urlSomQRParam, urlTabletQRParam} = this.getQRByUrlParams();
        product && unitId && KasperModule.fetchUnitIdentity(product.toLowerCase(), unitId.toLowerCase());
        PackageManagerModule.fetchProducts();
        this.state = {
            loading: !!unitId || (!unitId && Object.keys(PackageManagerModule.getProducts()).length <= 0),
            product: product || "elliq",
            unitId: unitId || "",
            somBarcode: urlSomQRParam,
            tabletBarcode: urlTabletQRParam,
            QRCodesByUrlParams: !!urlSomQRParam && !!urlTabletQRParam,
            somDisabled: false,
            tabletDisabled: false,
            devUnit: false,
            softwareOnly: false
        };
    }

    getQRByUrlParams = () => {
        const urlSomQRParamString = LoadableComponent.getQueryParameter(QueryParam_SomQR);
        const urlTabletQRParamString = LoadableComponent.getQueryParameter(QueryParam_TabletQR);

        if (!urlSomQRParamString || !urlTabletQRParamString)
            return {
                urlSomQRParam: undefined,
                urlTabletQRParam: undefined
            };

        let urlSomQRParam;
        let urlTabletQRParam;
        try {
            urlSomQRParam = JSON.parse(atob(decodeURIComponent(urlSomQRParamString)))?.barcode;
            urlTabletQRParam = JSON.parse(atob(decodeURIComponent(urlTabletQRParamString)))?.barcode;
        } catch (e) {
        }

        return {
            urlSomQRParam,
            urlTabletQRParam
        };
    };

    __onRequestCompleted(requestKey: string, success: boolean) {
        this.setState(state => {
            const newState = {
                ...state,
                loading: false
            };

            if (success) {
                switch (requestKey) {
                    case RequestKey_UnpairUnit:
                        DialogModule.close();
                        break;
                    case RequestKey_PairUnit:
                        if (state.repair)
                            break;

                        newState.somBarcode = undefined;
                        newState.tabletBarcode = undefined;
                        newState.somDisabled = false;
                        newState.tabletDisabled = false;
                        newState.devUnit = false;
                        newState.softwareOnly = false;
                        newState.unitId = undefined;
                        newState.comment = undefined;
                }
            }

            return newState;
        });

    };

    onUnitIdentityFetched(unit: UnitIdentity) {
        let somBarcode: string, tabletBarcode: string;
        unit.devices.forEach(device => {
            if (device.type === "som")
                somBarcode = JSON.stringify(device);
            if (device.type === "tablet")
                tabletBarcode = JSON.stringify(device);
        });
        this.setState(
            () => ({
                repair: true,
                somDisabled: true,
                tabletDisabled: true,
                factoryIdDisabled: true,
                product: unit.product,
                unitId: unit.unitId,
                devUnit: !!unit.devUnit,
                softwareOnly: !!unit.softwareOnly,
                somBarcode: somBarcode,
                tabletBarcode: tabletBarcode,
                version: unit.version,
                comment: unit.comment,
                loading: false
            }));
    }

    __onDeviceAlreadyPaired(pairedDevice: PairedDevice): void {
        this.setLoading(false);

        const content = <div style={{padding: 20}}>
            The {pairedDevice.product} is already paired to the {pairedDevice.deviceType} {pairedDevice.unitId}
            {this.renderUnpairButton(pairedDevice)}
        </div>;

        new Dialog_Builder(content)
            .addButton(closeButton())
            .show();
    }

    __onDeviceWithSameSerialAlreadyInSystem(body: DeviceWithSameSerialAlreadyInSystem): void {
        this.setLoading(false);
        const content = <div style={{padding: 20}}>
            Duplicate serials. device with serial number: {body.serial} already in system, please remove it first then pair
        </div>;

        new Dialog_Builder(content)
            .addButton(closeButton())
            .show();
    }

    __onPairingError(message: string): void {
        this.setLoading(false);
        ToastModule.toastError(message);
    };

    __onInvalidSerial(message: string): void {
        this.setLoading(false);
        ToastModule.toastError(message);
    }

    private renderUnpairButton(pairedDevice: PairedDevice) {
        if (!checkPermissions(PermissionAccessLevel_Units, PermissionAccessLevel_Units.Delete, PermissionKey_Units)())
            return "";

        return <div onClick={() => this.onUnpairClick(pairedDevice.unitId, pairedDevice.product, this.state.comment)} className={"ll_h_c"}
                    style={{justifyContent: "center", padding: 20}}>
            Unpair <img alt="" className={`clickable`} src={icon__unpair} style={{paddingLeft: 10}}/>
        </div>;
    }

    onUnpairClick(unitId: string, product: string, userGroup?: string) {
        Page_DevicePairing.showUnpairDialog(unitId, product, userGroup);
    }

    public static showUnpairDialog(unitId: string, product: string, userGroup?: string) {
        const content =
            <div className={`ll_v_c match_width`} style={{justifyContent: "space-evenly"}}>
                <img src={icon__warning} alt="warning" style={{paddingBottom: 10}}/>
                <div style={{padding: 10}}>You are about to unpair unit<br/>
                    <b>{unitId}</b>.<br/>
                    This process cannot be undone!
                </div>
            </div>;
        new Dialog_Builder(content)
            .setTitle("Unpair unit")
            .setButtons(...confirmationButtons(async () => {
                KasperModule.unpairUnit(unitId, product, userGroup);
            }, "Unpair"))
            .show();
    }

    handleValueChange = (value: string, id: string) => this.onScannedInput(value);

    protected onScannedInput(input: string): void {
        try {
            const barcode: BarcodeV1 = JSON.parse(input);
            if (!barcode.sha256)
                return this.onPreSubmitFailure("Please update to latest Kaspero");

            if (barcode.type === "som") {
                if (this.state.somDisabled)
                    return this.onPreSubmitInfo("To enter SOM qr, please enable *SOM* repair.");

                return this.setState({somBarcode: input});
            }

            if (barcode.type === "tablet") {
                if (this.state.softwareOnly)
                    return ToastModule.toastError("You can't change manually tablet barcode when you in software only mode");

                if (this.state.tabletDisabled)
                    return this.onPreSubmitInfo("To enter Tablet qr, please enable *tablet* repair.");

                return this.setState({tabletBarcode: input});
            }

            this.onPreSubmitFailure(`Wrong barcode type: ${barcode.type}`);
        } catch (e) {
            return this.onPreSubmitFailure(`Not a barcode...`);
        }
    }

    onPreSubmitFailure(errorMessage: string) {
        ToastModule.toastError(errorMessage);
    }

    onPreSubmitInfo(infoMessage: string) {
        ToastModule.toastInfo(infoMessage);
    }

    handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!this.state.somBarcode)
            return this.onPreSubmitFailure("Missing SOM barcode");

        try {
            const barcode: BarcodeV1 = JSON.parse(this.state.somBarcode);
            if (!barcode || barcode.type !== "som")
                return this.onPreSubmitFailure("Invalid SOM barcode!!!");
        } catch (e) {
            return this.onPreSubmitFailure("Invalid SOM barcode!!!");
        }

        if (!this.state.tabletBarcode)
            return this.onPreSubmitFailure("Missing Tablet barcode");

        try {
            const barcode: BarcodeV1 = JSON.parse(this.state.tabletBarcode);
            if (!barcode || barcode.type !== "tablet")
                return this.onPreSubmitFailure("Invalid Tablet barcode!!!");
        } catch (e) {
            return this.onPreSubmitFailure("Invalid Tablet barcode!!!");
        }

        if (!this.state.unitId || this.state.unitId === "" || !elliqIdRe.test(this.state.unitId))
            return this.onPreSubmitFailure("Elliq ID must follow convention! For details, please open tooltip");


        const requestObject: Request_Pair = {
            product: this.state.product,
            unitId: this.state.unitId,
            somBarcode: JSON.parse(this.state.somBarcode),
            tabletBarcode: JSON.parse(this.state.tabletBarcode),
            version: this.state.version,
            devUnit: !!this.state.devUnit,
            softwareOnly: !!this.state.softwareOnly
        };
        if (this.state.comment)
            requestObject.comment = this.state.comment;

        this.setLoading(true);

        KasperModule.pairUnit(requestObject, !!this.state.repair);
    };

    renderPage() {
        // const formContainer = {width: "50%", margin: 5, padding: 10};
        // const formBodyContainer = {maxHeight: "18rem", overflow: "auto"};
        return (
            <div className="ll_v_c">
                {this.state.repair && <h1>Repair existing unit</h1>}
                <form onSubmit={this.handleSubmit} className="ll_v_c" autoComplete="off">
                    <div className="ll_h_c">
                        {this.renderSomQR()}
                        {this.renderField_SoftwareOnly()}
                        {this.renderTabletQR()}
                    </div>
                    <div className="ll_h_c" style={{justifyContent: this.state.repair ? "space-between" : "center", width: "90%", height: "4rem"}}>
                        {this.state.repair && <Button
                            style={{width: "190px"}}
                            onClick={() => this.setState((prev) => ({somDisabled: !prev.somDisabled}))}>{this.state.somDisabled ? "Enable" : "Disable"} SOM repair
                        </Button>}
                        {(!this.state.somDisabled || !this.state.tabletDisabled) &&
                            <img alt={"QR code btn"} className="qrcode-text-btn clickable" src={icon__scanQrFromPhone} onClick={this.openQRCamera}/>}
                        {this.state.repair && <Button
                            style={{width: "190px"}}
                            onClick={() => this.setState((prev) => ({tabletDisabled: !prev.tabletDisabled}))}>{this.state.tabletDisabled ? "Enable" : "Disable"} tablet repair
                        </Button>}
                    </div>
                    {(!this.state.somDisabled || !this.state.tabletDisabled) && this.renderButton_QRScanner()}
                    <div style={{height: 320}} hidden={!this.state.scanBarcodeFromPhone}>
                        <label>Scan barcode</label>
                        <div id={"barcodeCam"} className="ll_v_c"/>
                    </div>
                    <div className="ll_h_c">
                        {this.renderField_DevUnit()}
                        {this.renderFieldComment()}
                    </div>
                    {this.renderField_ElliqId()}
                    {this.renderField_VersionSuffix()}
                    <div>
                        <input type="submit" value="Submit" style={{marginTop: "10px"}}/>
                    </div>
                </form>
            </div>
        );
    }

    private renderField_VersionSuffix() {
        return <div className={`ll_h_c ${marginTop(20)}`}>
            <InputTextField
                label="Version suffix:"
                value={this.state.version || ""}
                onValueChange={(version: string, id: string) => {
                    this.setState({version: version || undefined});
                }}
                id="version"
                type="text"
            />
        </div>;
    }

    private renderField_DevUnit() {
        return (
            <div style={{position: "relative", top: -20, right: 25}}>
                <p>Dev unit:</p>
                <span style={{position: "relative", top: 10}}>
					<TS_Checkbox
                        id={"dev-unit-checkbox"}
                        label={""}
                        onCheck={(checked) => this.setState(() => ({devUnit: !checked}))}
                        value={this.state.devUnit}
                        checked={!!this.state.devUnit}
                        circle
                    />
				</span>
            </div>);
    }

    private renderField_SoftwareOnly() {
        return <PermissionsComponent url={Url_SupportSoftwareOnly}>
            <div style={{position: "relative", width: 150}}>
                <p>Software only:</p>
                <span style={{position: "relative", display: "flex", marginLeft: 37}}>
					<TS_Checkbox
                        id={"so-checkbox"}
                        label={""}
                        onCheck={(checked) => {
                            if (this.state.softwareOnly)
                                return this.setState({tabletBarcode: "", softwareOnly: false});

                            if (!this.state.somBarcode)
                                return ToastModule.toastError("Please fill som barcode first");

                            if (this.state.tabletBarcode)
                                return ToastModule.toastError("Tablet barcode should be empty");

                            const objSomBarcode: BarcodeV1 = JSON.parse(this.state.somBarcode);
                            const fakeTabletBarcode = KasperModule.getFakeTabletBarcodeForSO(objSomBarcode);

                            this.onScannedInput(JSON.stringify(fakeTabletBarcode));
                            this.setState(() => ({softwareOnly: true}))
                        }}
                        value={this.state.softwareOnly}
                        checked={!!this.state.softwareOnly}
                        circle
                    />
				</span>
            </div>
        </PermissionsComponent>;
    }

    private renderField_ElliqId() {
        return <div className={`ll_h_c ${marginTop(30)}`}>
            <InputTextField
                label="Unit ID:"
                value={this.state.unitId || ""}
                maxLength={40}
                onValueChange={(unitId: string, id: string) => {
                    this.setState({unitId: unitId.toLowerCase().trim()});
                }}
                id="elliqId"
                type="text"
            />
        </div>;
    }

    private renderTabletQR() {
        if (this.state.softwareOnly)
            return null;

        return (
            <div className="ll_h_c" style={{width: "50%"}}>
                <InputTextAreaField
                    label="Tablet QR Info:"
                    value={this.state.tabletBarcode ? this.state.tabletBarcode : ""}
                    onValueChange={this.handleValueChange}
                    id="_tabletBarcode"
                    rows={10}
                    readOnly={true}
                    disabled={true}
                />
            </div>);
    }

    private renderSomQR() {
        return (
            <div className="ll_h_c" style={{width: this.state.softwareOnly ? "100%" : "50%"}}>
                <InputTextAreaField
                    label="SOM QR Info:"
                    value={this.state.somBarcode ? this.state.somBarcode : ""}
                    onValueChange={this.handleValueChange}
                    id="_somBarcode"
                    rows={10}
                    readOnly={true}
                    disabled={true}
                />
            </div>);
    }

    private renderButton_QRScanner = () => (
        this.state.scanQrFromPhone &&
        <div>
            <label>Scan QR</label>
            <Html5QrcodePluginFallback qrCodeSuccessCallback={this.handleScanSuccess}/>

            {/*<QrReader*/}
            {/*    delay={300}*/}
            {/*    onError={this.handleScanError}*/}
            {/*    onScan={this.handleScanSuccess}*/}
            {/*    style={{width: "35rem"}}*/}
            {/*/>*/}
        </div>
    );

    private renderFieldComment = () => {
        return <PermissionsComponent url={Url_UpdateEnv}>
            <div style={{position: "relative", top: -13}}>
                <p>Unit Env</p>
                <span>
					<SetUserGroup
                        userGroup={this.state.comment}
                        onUserGroupChange={comment => this.setState({comment})}
                    />
				</span>
            </div>
        </PermissionsComponent>;
    };
}


export default Page_DevicePairing;
