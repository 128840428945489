import * as React from 'react';
import * as emotion from "emotion";

// const error = require('@res/images/icn-warning.svg');

export const labelStyle = emotion.css`
	color: #3B3B3B;
	font-size: 14px;
	font-weight: 400;
  letter-spacing: -0.41px;
  padding-bottom: 4px;
`;

const errorText = emotion.css`
	font-size: 13px;
	font-weight: 400;
	// line-height: 22px;
	letter-spacing: -0.4099999964237213px;
	text-align: left;
	color: #941D29;
`;

const inputStyle = emotion.css`
	&:-internal-autofill-selected {
	    background-color: #2e304f !important;
	}
	background-color: inherit;
  padding-left: 10px;
  width: 87%;
  height: inherit;
  color: #000000e8;
  font-family: 'font-light', sans-serif;
  outline: none;
  ::placeholder {
    font-family: 'font-light', sans-serif;
    color: #979797;
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.41px;
  }
`;

export const inputContainerStyle = emotion.css`
	border: 0.9px solid #C0C0C0;
	margin-bottom: 20px;
	width: 100%;
	border-radius: 10px;
	height: 40px;
`;

type Props<Key extends string> = {
	onChange: (value: string, id: Key) => void
	onAccept?: () => void
	onBlur?: () => void
	disabled?: boolean
	value?: string
	error?: string
	label: string
	type: 'text' | 'number' | 'password' | 'date'
	placeholder?: string
	height?: number
	wrapperStyle?: React.CSSProperties
	id: Key
}

export const NewStyledInput = <Key extends string>(props: Props<Key>) => {
	const {label, id, type, value} = props;
	let style = {height: "40px", border: ""}
	if (props.error)
		style = {border: "0.9px solid #941D29", height: "40px"};
	if (props.height)
		style.height = props.height + "px";

	return (
		<div key={id} className="ll_v_l" style={props.wrapperStyle}>
			<div className={labelStyle}>{`${label}`}</div>
			<div className={`ll_h_c`} style={{width: "100%"}}>
				<div className={inputContainerStyle} style={style}>
					<input
						id={id}
						key={id + "input"}
						className={inputStyle}
						type={type}
						value={value || ''}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							props.onChange(event.target.value, event.target.id as Key)
						}}
						onBlur={props.onBlur}
						autoComplete={'off'}
						disabled={props.disabled}
						style={props.disabled ? {color: "gray"} : {}}
					/>
					{props.error && <div key={id + "error"} className={errorText}>*{props.error}</div>}
				</div>
			</div>
		</div>
	);
};
