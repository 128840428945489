import * as React from "react";
import {Snackbar} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export enum ToastType {
	"success",
	"error",
	"info"
}

export type CustomSnackBarProps = {
	toast: Toast;
	id?: string
	onClose: () => void
	extraActions?: React.ReactNode[]
	vertical?: "bottom" | "top";
	horizontal?: "left" | "right" | "center";
}

export type Toast = {
	duration?: number;
	bgColor?: string;
	type: ToastType;
	message: string;
}

class CustomSnackBar
	extends React.Component<CustomSnackBarProps, {}> {
	constructor(props: CustomSnackBarProps) {
		super(props);
	}

	render() {
		const toast = this.props.toast;
		if (!toast)
			return "";

		if (!toast.message)
			return "";

		let message = toast.message;
		message = message.replace(/\n#### (.*?)\n/g, "\n<h4>$1</h4>\n");
		message = message.replace(/\n### (.*?)\n/g, "\n<h3>$1</h3>\n");
		message = message.replace(/\n## (.*?)\n/g, "\n<h2>$1</h2>\n");
		message = message.replace(/\n# (.*?)\n/g, "\n<h1>$1</h1>\n");
		message = message.replace(/(<\/?.*?>)\n/g, "$1");
		message = message.replace(/([^>]?)\n/g, "$1<br/> ");
		const ignore = message.match(/`(.*?)`/g);
		if (ignore && ignore.length > 0)
			message = ignore.reduce((input, toEscape) => {
				const replaceValue = toEscape.substring(1, toEscape.length - 1)
				                             .replace(/([^\\]?)_/g, "$1\\_")
				                             .replace(/([^\\]?)\*/g, "$1\\*");

				return input.replace(toEscape, replaceValue);
			}, message);

		message = message.replace(/([^\\]?)_(.*?)([^\\])_/g, "$1<i>$2$3</i>");
		message = message.replace(/([^\\]?)\*(.*?)([^\\])\*/g, "$1<b>$2$3</b>");
		message = message.replace(/\\_/g, "_");
		message = message.replace(/\\\*/g, "*");

		return (
			<Snackbar
				id={this.props.id}
				anchorOrigin={{
					vertical: this.props.vertical || 'bottom',
					horizontal: this.props.horizontal || 'left'
				}}
				open={!!toast}
				autoHideDuration={toast.duration || 10000}
				onClose={this.props.onClose}
				ContentProps={{
					style: {
						backgroundColor: CustomSnackBar.getBackgroundColor(toast)
					},
					'aria-describedby': 'sb-message'
				}}
				message={<div dangerouslySetInnerHTML={{__html: message}} style={{color: this.getTextColor(toast)}}/>}
				action={this.renderActions()}
			/>
		);
	}

	private renderActions = () => {
		const actions: React.ReactNode[] = [
			<IconButton
				key="close"
				aria-label="Close"
				color="inherit"
				onClick={this.props.onClose}
			>
				<CloseIcon/>
			</IconButton>
		];
		actions.push(...(this.props.extraActions || []));
		return actions;
	};

	private static getBackgroundColor(toast: Toast) {
		if (toast.bgColor)
			return toast.bgColor;

		switch (toast.type) {
			case ToastType.success:
				return "#388e3c";

			case ToastType.error:
				return "#d32f2f";

			case ToastType.info:
				return "#caf7f6";

		}
		return "#e8e8e8";
	}

	private getTextColor(toast: Toast) {
		switch (toast.type) {
			case ToastType.error:
			case ToastType.success:
				return "#ffffff";

			case ToastType.info:
				return "#000000";
		}
		return "#000000";
	}
}

export default CustomSnackBar;




