import * as React from 'react';
import {InputTextAreaField} from "@components/InputTextAreaField";
import * as emotion from "emotion";
//@ts-ignore
import Quagga from 'quagga';
import {
	LoadableComponent,
	LoadableProps,
	LoadableState
} from "@components/LoadableComponent";
import {ToastModule} from '@intuitionrobotics/thunderstorm/frontend';
import { _setTimeout } from '@intuitionrobotics/ts-common';
import {UnitsModule} from "@modules/UnitsModule";

const hiddenTextArea = emotion.css`
  width: 200px;
  height: 10px;
  padding: 4px;
  border-radius: 4px !important;
  box-shadow: 0 0 0 1px rgba(41, 41, 41, 0.2) inset;
  font-size: 10px;
  color: #242424;
  display: none;
  border: none;
  resize: none;
`;

const placeholderId = "placeholder";
export type Scanning = LoadableState & {
	scanQrFromPhone?: boolean;
	scanBarcodeFromPhone?: boolean;
	showPasteModal?: boolean;
	somDisabled?: boolean;
	tabletDisabled?: boolean;
	repair?: boolean;
}


abstract class Page_ScanningPage<P extends LoadableProps, S extends Scanning>
	extends LoadableComponent<P, S> {

	private input: string = "";
	private listener: number = -1;

	protected constructor(props: P) {
		super(props);
	}

	componentDidMount(): void {
		if (window === undefined)
			return;
		window.addEventListener("keydown", this.handleInput);
		//@ts-ignore
		window.addEventListener("paste", this.handlePaste);
	}

	componentWillUnmount(this: any): void {
		window.removeEventListener("keydown", this.handleInput);
		window.removeEventListener("paste", this.handlePaste);
	}

	protected handlePaste = (event: React.ClipboardEvent) => {
		if (this.state.somDisabled && this.state.tabletDisabled)
			return ToastModule.toastInfo("Please enable SOM repair or Tablet repair");

		const value = event.clipboardData ? event.clipboardData.getData('text') : "";
		this.onScannedInput(value);
	};

	protected handleInput = (event: KeyboardEvent) => {

		if (this.state.somDisabled && this.state.tabletDisabled)
			return ToastModule.toastInfo("Please enable SOM repair or Tablet repair");

		if (placeholderId !== document.activeElement?.id)
			return;

		this.logDebug(`key: ${event.key}`);

		if (event.ctrlKey || event.metaKey) {
			// this.input += navigator.clipboard.getData('text');
			return;
		}

		if (event.key === "Enter") {
			event.preventDefault();
			return;
		}

		if (event.key.length === 1) {
			this.setLoading(true);

			event.preventDefault();
			this.input += event.key;
			if (this.listener !== -1)
				clearTimeout(this.listener);

			this.listener = _setTimeout(() => {
				this.logDebug(`Timeout expired: ${this.input}`);
				this.onScannedInput(this.input);
				this.input = "";
				this.setLoading(false);
				this.forceUpdate();
			}, 300)
		}

	};

	protected abstract onScannedInput(input: string): void ;

	protected abstract renderPage(): React.ReactNode;

	//All phone camera button stuff

	handleScanSuccess = (data: any) => {
		if (data) {
			this.onScannedInput(data);
			this.setState(() => ({
				scanQrFromPhone: false
			}))
		}
	};

	handleScanError = (error: any) => {
		if (error) {
			this.onScannedInput("error");
			console.error(error);
			this.setState(() => ({
				scanQrFromPhone: false
			}))
		}
	};

	openQRCamera = (event: React.FormEvent) => {
		event.preventDefault();
		this.setState((prev) => ({
			scanQrFromPhone: !prev.scanQrFromPhone,
			scanBarcodeFromPhone: false
		}));
		Quagga.stop();
	};

	protected openBarcodeCamera = (event: React.FormEvent) => {
		event.preventDefault();
		if (this.state.scanBarcodeFromPhone) {
			Quagga.offDetected(this.handleBarcodeFromCamera);
			Quagga.stop();
		} else {
			//@ts-ignore
			Quagga.init({
				            inputStream: {
					            name: "Live",
					            type: "LiveStream",
					            constraints: {
						            width: 480,
						            height: 300,
						            display: "none",
						            facingMode: "environment"
					            },
					            target: document.querySelector('#barcodeCam')
				            },
				            decoder: {
					            readers: ["code_128_reader"],
					            multiple: false
				            }
				            //@ts-ignore
			            }, function (err) {
				if (err) {
					UnitsModule.logError(err);
					return
				}
				Quagga.start();
				UnitsModule.logInfo("Initialization finished. Ready to start");
			});
			Quagga.onDetected(this.handleBarcodeFromCamera);
		}
		this.setState((prev) => ({
			scanBarcodeFromPhone: !prev.scanBarcodeFromPhone,
			scanQrFromPhone: false
		}))
	};

	handleBarcodeFromCamera = (data: any) => {
		this.setState(() => ({
			scanBarcodeFromPhone: false
		}));
		this.onScannedInput(data.codeResult.code);
		Quagga.offDetected(this.handleBarcodeFromCamera);
		Quagga.stop();
	};

	//finished All phone camera button stuff
	protected renderComponent(): React.ReactNode {
		return (
			<>
				<div className="match_height match_width ll_v_c">
					<InputTextAreaField
						style={hiddenTextArea}
						value={this.input}
						id={placeholderId}
						readOnly={false}
						autoFocus={true}
						onValueChange={(value) => {
							this.input = value;
							this.logDebug(`value: ${value}`);
							this.forceUpdate()
						}}/>
					{this.renderPage()}
				</div>
			</>
		);
	}
}

export default Page_ScanningPage;
