import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {OnRequestListener} from '@intuitionrobotics/thunderstorm';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Renderer_Loader from "@renderers/Renderer_Loader";
import {
	FormControl,
	Icon,
	IconButton
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import SearchIcon from '@mui/icons-material/Search';
import {
	marginLeft,
	marginTop
} from "@styles/dynamic-styles";
import {
	PermissionsModule,
	RequestKey_ChangedPermissions
} from "@modules/PermissionsModule";
import {
	createFilterPattern,
	sortArray
} from "@intuitionrobotics/ts-common";
import {COLOR_lightGray} from "@styles/colors";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import {AddCircleOutline} from "@mui/icons-material";
import {SortOrder} from "@components/table/consts";
import CloseIcon from "@mui/icons-material/Close";
import {Client_PermissionsGroup} from "@app/ir-q-app-common/types/permissions";

type State_Permissions = {
	sortOrder: SortOrder
	sortBy?: string,
	groupName?: string,
	filter: string,
	filterPattern: string,
	loading: boolean,
}

export default class Tab_PermissionsGroup
	extends BaseComponent<{}, State_Permissions>
	implements OnRequestListener {

	constructor(props: any) {
		super(props);
		this.state = {
			filter: "",
			filterPattern: createFilterPattern(""),
			loading: true,
			sortOrder: SortOrder.DESC
		};

		this.onFilterValueChange = this.onFilterValueChange.bind(this);
		this.onSortClick = this.onSortClick.bind(this);
		this.renderRow = this.renderRow.bind(this);
	}

	componentDidMount(): void {
		PermissionsModule.fetchPermissions();
	}

	__onRequestCompleted = (key: string) => {
		switch (key) {
			default:
				return;

			case RequestKey_ChangedPermissions:
				break;
		}

		this.setState({loading: false});
	};

	onFilterValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState(
			{
				filter: event.target.value,
				filterPattern: createFilterPattern(event.target.value)
			});
	};

	onGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.logDebug("group name: " + event.target.value);
		this.setState(
			{
				groupName: event.target.value
			});
	};

	onSortClick(e: React.MouseEvent) {
		const newOrder = this.state.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
		this.setState(
			{
				sortBy: Tab_PermissionsGroup.getElementId(e),
				sortOrder: newOrder
			}
		);
	}


	render() {
		this.logWarning(`loading: ${this.state.loading}`);
		return (
			<div style={{marginLeft: 30, marginRight: 30}}>
				<div className="match_height match_width">
					<div className={`ll_v_c ${marginTop(20)}`}>
						<Paper elevation={1}>
							<InputBase
								onChange={this.onFilterValueChange}
								value={this.state.filter || ""}
								id="unit-filter"
								style={{marginLeft: 8}}
								placeholder="Group name"/>

							<IconButton aria-label="Search"> <SearchIcon/> </IconButton>
						</Paper>
					</div>
					{Renderer_Loader(this.state.loading)}
					{!this.state.loading && this.renderTable()}
				</div>
			</div>
		);
	}

	createNewGroup = () => {
		if (!this.state.groupName) {
			return;
		}

		this.setState({loading: true});
		PermissionsModule.updatePermissionsGroup({name: this.state.groupName});
	};

	private renderTable() {
		const filterRegexp = new RegExp(this.state.filterPattern || ".*");
		const permissionsGroups = PermissionsModule.getPermissionGroups();
		const groups = Object.keys(permissionsGroups).filter((name) => filterRegexp.test(name)).map(name => permissionsGroups[name]);
		let sortedUsers = sortArray(groups, (item: Client_PermissionsGroup) => item.name);
		if (this.state.sortOrder === SortOrder.DESC)
			sortedUsers = sortedUsers.reverse();

		const features = PermissionsModule.getFeatureAccessLevels();
		if (!features)
			return "No Permissions features found";

		return <>
			<div className="ll_h_c">
				<div style={{margin: 20}}>{sortedUsers.length} Groups</div>
			</div>
			<div className="ll_h_c">
				<Icon id="addIcon" aria-label="AddCircleOutline" fontSize={"inherit"} onClick={this.createNewGroup}>
					<AddCircleOutline fontSize={"inherit"} color={"inherit"}/>
				</Icon>
				<InputBase
					onChange={this.onGroupNameChange}
					value={this.state.groupName || ""}
					id="group-name"
					style={{marginLeft: 8}}
					placeholder="group name"/>
			</div>


			<Table>
				<TableHead style={{backgroundColor: "gainsboro"}}>
					<TableRow>
						<TableCell key="email" align="left" style={{color: "black"}}> Group </TableCell>
						{features.map(feature =>
							              <TableCell key={feature.key} align="left" style={{color: "black"}}>
								              <div className="ll_h_c">
									              <div className={marginLeft(4)}>{feature.key}</div>
								              </div>
							              </TableCell>
						)}
						<TableCell key="actions" align="right" style={{color: "black"}}> Actions </TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{sortedUsers.map(this.renderRow)}</TableBody>
			</Table>
		</>;
	}

	private renderRow(group: Client_PermissionsGroup) {
		const email = group.name;
		return (
			<TableRow key={group.name} hover={true} style={{background: COLOR_lightGray}}>
				<TableCell key="email" component="th" scope="row">{email}</TableCell>
				{PermissionsModule.getFeatureAccessLevels().map(
					feature => {
						const permission = (group.permissions || {})[feature.key];
						const value = !permission ? feature.levels[0] : permission;
						return <TableCell key={feature.key} component="th" scope="row">
							<FormControl style={{minWidth: 130, margin: '5px'}}>
								<Select
									value={value}
									onChange={async (event) => {
										(group.permissions || (group.permissions = {}))[feature.key] = event.target.value;
										PermissionsModule.updatePermissionsGroup(group);
									}}
									input={<Input name={feature.key}/>}
								>
									{feature.levels.map((level) => <MenuItem key={level} value={level}>{level}</MenuItem>)}
								</Select>
							</FormControl>
						</TableCell>;
					})}
				<TableCell key='delete' component="th" scope="row" align={'right'}>
					{group.id && <IconButton
						key="delete"
						aria-label="Delete"
						onClick={() => PermissionsModule.deletePermissionGroup(group.id)}
					>
						<CloseIcon color={'error'}/>
					</IconButton>}
				</TableCell>
			</TableRow>
		);
	}
}
