import * as React from 'react';
import './style.css';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button/Button";
import {SimpleLoader} from "@components/SimpleLoader";
import {useState} from "react";
import {SpeechServerModule} from "@modules/SpeechServerModule";
import {SpeechMessage} from "@app/ir-q-app-common/types/speech";

function LlmConversation(props: { unitId: string }) {
    const unitId = props?.unitId;
    const [typedMessage, setTypedMessage] = useState("");
    const [loading, setLoading] = useState(false)
    const [messages, setMessages] = useState<SpeechMessage[]>([]);
    const [rootExecutionId, setRootExecutionId] = useState('');
    const [showSessionId, setShowSessionId] = useState('');

    if (!unitId)
        return;

    const enterPressed = async () => {
        setLoading(true);
        setMessages((prevMessages) => [...prevMessages, {role: "user", content: typedMessage, timestamp: Date.now(), sessionId: ''}]);
        setTypedMessage('');

        const result = await SpeechServerModule.llmTurn(unitId, typedMessage, messages, rootExecutionId);
        setRootExecutionId(result.rootExecutionId);
        setMessages((prevMessages) => [...prevMessages, result.response]);
        setLoading(false);
    };

    const renderMessage = (message: SpeechMessage) => {
        const messageClass = message.role === 'user' ? 'user-message' : 'elliq-message';
        const messageWrapperClass =message.role === 'user' ? 'user' : 'elliq';
        return <div className={`message-wrapper ${messageWrapperClass}`}>
            {message.timestamp && <div className={"timestamp"}>{new Date(message.timestamp).toLocaleString()}</div>}
            <div className={`message ${messageClass}`}  onClick={() => setShowSessionId(message.sessionId === showSessionId ? '' : message.sessionId)}>
                {message.content}
                {showSessionId === message.sessionId && <div>{message.sessionId}</div>}
            </div>
        </div>;
    }

    const renderMessages = () => {
        const sortedMessages = messages.sort((a, b) => a.timestamp - b.timestamp);
        return <div>{sortedMessages.map((message) => renderMessage(message))}</div>
    }

    return <div className="conversation-container">
        <div className="message-container">
            {renderMessages()}
        </div>
        {<div className={"ll_h_c space-between"}>
            <TextField
                fullWidth
                multiline
                maxRows={3}
                id="filled-basic"
                variant="standard"
                onChange={(e) => setTypedMessage(e.target.value)}
                placeholder="Type your message..."
                onKeyDown={e => e.key === "Enter" && enterPressed()}
                value={typedMessage}
            />
            <Button
                variant="contained"
                onClick={() => enterPressed()}
            >Send</Button>
        </div>}
        {loading && <SimpleLoader backgroundColor={"transparent"} absolute={true}/>}
    </div>
}

export default LlmConversation;