import {Module} from "@intuitionrobotics/ts-common";
import {ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {ApiSpeechTurn, SpeechMessage} from "@app/ir-q-app-common/types/speech";
type Config = {
    serverUrl: string
}

export class SpeechServerModule_Class
    extends Module<Config> {

        llmTurn(unitId: string, message: string, messages: SpeechMessage[], rootExecutionId: string) {
            return XhrHttpModule
                .createRequest<ApiSpeechTurn>(HttpMethod.POST, "sayHello")
                .setRelativeUrl(`/v1/speech/turn`)
                .setJsonBody({ unitId: unitId, message: message, messages: messages, rootExecutionId: rootExecutionId })
                .setLabel(`Speech server turn`)
                .setOnError(() => { ToastModule.toastError(`Error speech server`)})
                .executeSync();
        }
}

export const SpeechServerModule = new SpeechServerModule_Class("SpeechServerModule");
