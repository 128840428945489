import * as React from "react";
import {padding} from "@styles/dynamic-styles";
import {Menu, MenuItem, Tooltip} from "@mui/material";

export function NavigateButton({options, icon, tooltip}: { options: { url: string, label?: string }[], icon: string, tooltip?: string }) {
    const size = 30;
    if (options.length === 1) {
        return <Tooltip title={tooltip}>
            <img
                alt={tooltip}
                className={`${padding(5)} clickable`}
                style={{width: size, height: size}} src={icon}
                onClick={() => window.open(options[0].url, "_blank")}/>
        </Tooltip>
    }
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{display: "flex"}}>
            <Tooltip title={tooltip}>
                <img
                    alt={tooltip}
                    className={`${padding(5)} clickable`}
                    style={{width: size, height: size}} src={icon}
                    onClick={handleClick}/>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                {options.map(({url, label}, index) => <MenuItem key={index} onClick={() => window.open(url, "_blank")}>{label || url}</MenuItem>)}
            </Menu>
        </div>
    );
}
