import {StorageKey} from "@intuitionrobotics/thunderstorm/frontend";
import {QueryParam_Email} from "@app/ir-q-app-common/types/auth";


export const StorageKey_UserEmail: StorageKey<string> = new StorageKey<string>(`storage-${QueryParam_Email}`);

export const QueryParam_UnitId = "unitId";
export const QueryParam_ConfigId = "configId";
export const QueryParam_Product = 'product';
export const QueryParam_UserGroup = 'userGroup';
export const QueryParam_Version = 'version';
export const QueryParam_SomQR = 'somQR';
export const QueryParam_TabletQR = 'tabletQR';

export type ConfigId = string;
export type Product = string;

export const PINNED_PUSH_COMMANDS_IDS = 'pinnedPushCommandsIds';

export function updateProperty<T extends object>(property: { [k: string]: T }, getKey: (element: T) => string, elements: T[]) {
	elements.reduce((carry, element) => {
		carry[getKey(element)] = element;
		return carry;
	}, property);
}

export const ReasonsToDisenrollUnit = [
	"Don't like it - I'm too active",
	"Don't like it - I don't like the features",
	"Don't like it - I don't need it",
	"Interaction Issues - ElliQ is too chatty",
	"Interaction Issues - I can't start a conversation with ElliQ",
	"Interaction Issues - ElliQ doesn't understand me",
	"Interaction Issues - Conversations are not smooth/intuitive",
	"Personal Reason",
	"Health Decline",
	"Passed Away",
	"Technical Issues",
	"Unknown",
	"None of the above"
]

export const ReasonsToCancelUnit = [
	"Order Canceled",
	"Network Environment Issues"
]