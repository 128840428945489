import * as React from 'react';
import * as emotion from "emotion";

// const error = require('@res/images/icn-warning.svg');

export const labelStyle = emotion.css`
	color: #3B3B3B;
	font-size: 14px;
	font-weight: 400;
  letter-spacing: -0.41px;
  padding-bottom: 4px;
`;

const errorText = emotion.css`
	font-size: 13px;
	font-weight: 400;
	// line-height: 22px;
	letter-spacing: -0.4099999964237213px;
	text-align: left;
	color: #941D29;
`;

const genderItem = emotion.css`
	width: 145px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
  border-radius: 40px;
  border: 0.9px solid #C0C0C0;
`;

const checkedGenderItem = emotion.css`
	background: linear-gradient(0deg, #5E6366, #5E6366);
	color: #FFFFFF;
	linear-gradient(0deg, #C0C0C0, #C0C0C0);
	width: 145px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
  border-radius: 40px;
	border: 0.9px solid #C0C0C0;
`;

// const inputStyle = emotion.css`
// 	&:-internal-autofill-selected {
// 	    background-color: #2e304f !important;
// 	}
// 	background-color: inherit;
//   padding-left: 10px;
//   width: 87%;
//   height: inherit;
//   color: #000000e8;
//   outline: none;
//   ::placeholder {
//     color: #979797;
//     font-weight: 200;
//     font-size: 16px;
//     line-height: 22px;
//     letter-spacing: -0.41px;
//   }
// `;

export const inputContainerStyle = emotion.css`
	border: 0.9px solid #C0C0C0;
	
	width: 100%;
	border-radius: 10px;
	height: 40px;
`;

type Props<Key> = {
	onChange: (value: string) => void
	onAccept?: () => void
	onBlur?: () => void
	disabled: boolean
	value?: string
	error?: string
	label: string
	type: 'text' | 'number' | 'password'
	icon?: string,
	genderList: string[]
	height?: number
	id: Key
}

type State = {}

export class GenderChoices<Key extends string>
	extends React.Component<Props<Key>, State> {
	static defaultProps: Partial<Props<"">> = {
		disabled: false
	};

	constructor(props: Props<Key>) {
		super(props);

		this.state = {};
	}

	render() {
		const {label, id, value} = this.props;
		let style = {height: "40px", border: ""}
		if (this.props.error)
			style={border: "0.9px solid #941D29", height: "40px"};
		if (this.props.height)
			style.height = this.props.height + "px";

		return (
			<div key={id} className="ll_v_l">
				<div className={labelStyle}>{`${label}`}</div>
				<div style={{width: "100%", height: "41px", marginBottom: "20px"}}>
					<div className={`ll_h_c`} style={{width: "100%", height: "41px", justifyContent: "space-between"}}>
					{this.props.icon && <img src={this.props.icon}/>}
						{
							this.props.genderList.map(gender => {
								return <div key={gender} className={value === gender ? checkedGenderItem : genderItem}
								            onClick={() => {
									            this.props.onChange(gender);
								            }
								            }>{gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase()}</div>;
							})
						}
					</div>
					{this.props.error && <span className={errorText}>*This is required field</span>}
				</div>
			</div>
		);
	}

}
