import {BaseComponent, TooltipBuilder, TooltipDefaultStyle} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {DB_PushMessageAndEnv} from "@app/ir-q-app-common/types/fb-push-messages";
import {PINNED_PUSH_COMMANDS_IDS} from "@consts/common";


const icon_pin = require('@res/images/pin.svg');
const icon_unpin = require('@res/images/unpin.svg');

export class PinButton extends BaseComponent<{obj: DB_PushMessageAndEnv, size?: number, tooltipContent?: string, onStart: () => void, onDone: () => void}> {

	getPinIcon() {
		const pinnedPushCommands = localStorage.getItem(PINNED_PUSH_COMMANDS_IDS);
		if (!pinnedPushCommands) {
			return icon_pin;
		}
		const pushCommands = JSON.parse(pinnedPushCommands);
		if (pushCommands.includes(this.props.obj.mId)) {
			return icon_unpin;
		}
		return icon_pin;
	}
	render() {
		const {size,obj, tooltipContent} = this.props;
		return <img
			key="redo"
			style={{width: size || 20, height: size || 20, paddingLeft: 5, cursor: 'pointer'}}
			src={this.getPinIcon()}
			onClick={e => {
				e.stopPropagation();
				this.props.onStart()
				const pinnedPushCommands = localStorage.getItem(PINNED_PUSH_COMMANDS_IDS);
				if (!pinnedPushCommands) {
					localStorage.setItem(PINNED_PUSH_COMMANDS_IDS, JSON.stringify([obj.mId]));
				} else {
					const pushCommands = JSON.parse(pinnedPushCommands);
					if (pushCommands.includes(obj.mId)) {
						const index = pushCommands.indexOf(obj.mId);
						pushCommands.splice(index, 1);
						localStorage.setItem(PINNED_PUSH_COMMANDS_IDS, JSON.stringify(pushCommands));
						this.renderTooltip(tooltipContent, e);

						this.props.onDone();
						return;
					}
					pushCommands.push(obj.mId);
					localStorage.setItem(PINNED_PUSH_COMMANDS_IDS, JSON.stringify(pushCommands));
				}

				this.renderTooltip(tooltipContent, e);

				this.props.onDone();
			}}
		/>;
	}

	private renderTooltip(tooltipContent: string | undefined, e: React.MouseEvent<HTMLImageElement>) {
		new TooltipBuilder(tooltipContent, e)
			.setLocation(e.clientX + 15, e.clientY - 15)
			.setStyle({...TooltipDefaultStyle, zIndex: 100})
			.setDuration(1000)
			.show();
	}
}
