import {ActionResult} from "@app/ir-q-app-common/types/push-messages";
import {DB_PushMessage, DB_PushMessageAndEnv} from "@app/ir-q-app-common/types/fb-push-messages";
import * as React from "react";
import {CSSProperties} from "react";
import {BaseComponent, TooltipModule} from "@intuitionrobotics/thunderstorm/frontend";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {marginLeft, padding} from "@styles/dynamic-styles";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import {FormControlLabel, Icon, Switch, TableContainer} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {createFilterPattern, SortOrder} from "@components/table/consts";
import {COLOR_iceBlue, COLOR_lightGreen, COLOR_lightOrange, COLOR_lightRed, COLOR_lightSkyBlue, COLOR_lightYellow, COLOR_orange, COLOR_purple} from "@styles/colors";
import {__stringify, cloneObj, ImplementationMissingException} from "@intuitionrobotics/ts-common";
import {DeviceType, Unit} from "@app/ir-q-app-common/types/units";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {showTooltip} from "@renderers/unit-info";
import {SimpleLoader, spin} from "@components/SimpleLoader";
import {css} from "emotion";
import {OnCancelPush, OnRedoPush, PushMessagesModule} from "@modules/PushMessagesModule";
import {PackageManagerModule, RequestKey_FetchApps} from "@modules/package-manager/PackageManagerModule";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";
import {CopyButton} from "../../../renderers/CopyButton";
import {UnitContext} from "../../../App";
import {PinButton} from "../../../renderers/PinButton";
import {PINNED_PUSH_COMMANDS_IDS} from "@consts/common";
import moment = require("moment");

const localStorageKey = 'pushCommandsTable-showAllPushMessages';

interface State {
    filters: { [index: string]: string };
    filterPatterns: { [index: string]: RegExp };
    sortOrder: SortOrder;
    sortBy: string;
    loading?: boolean;
    page: number;
    showAllPushMessages: boolean;
}

interface Props {
    count?: number
    isPage: boolean
    setIsPage: () => void
    unit: Unit
    content: DB_PushMessageAndEnv[]
    deviceType?: DeviceType
}

const spinnerCall = css({
    borderTop: '3px solid',
    borderRight: '3px solid',
    borderRadius: '50%',
    width: 18,
    height: 18,
    animation: `${spin} 1s linear infinite`
});

export class PushCommandsTable
    extends BaseComponent<Props, State>
    implements OnRequestListener, OnRedoPush {
    private maxPerPage: number = 100;

    constructor(props: Props) {
        super(props);

        const showAllPushMessages = localStorage.getItem(localStorageKey) === 'true';

        this.state = {
            sortBy: "clientResultTimestamp",
            sortOrder: SortOrder.ASC,
            filters: {
                timestamp: "",
                name: "",
                receiverApp: "",
                author: "",
                status: "",
                device: "",
                appFrom: (showAllPushMessages ? "" : "ir-q-support")
            },
            filterPatterns: {
                timestamp: /.*?/,
                name: /.*?/,
                receiverApp: /.*?/,
                author: /.*?/,
                status: /.*?/,
                device: /.*?/,
                appFrom: (showAllPushMessages ? /.*?/ : createFilterPattern("ir-q-support"))
            },
            loading: false,
            page: 0,
            showAllPushMessages
        };
    }

    __onRedo(mId?: string) {
        this.setState({loading: false})
    }

    __onRequestCompleted = (key: string) => {
        switch (key) {
            case RequestKey_FetchApps:
                return this.forceUpdate()
        }
    }

    private onSortClick = (e: React.MouseEvent, enabled: boolean) => {
        if (!enabled)
            return;

        let newOrder = this.state.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        const sortBy = PushCommandsTable.getElementId(e);
        if (this.state.sortBy !== sortBy)
            newOrder = SortOrder.ASC;

        this.setState(
            {
                sortBy: sortBy,
                sortOrder: newOrder
            }
        );
    };

    onFilterValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rawFilter = (event.target.value).toLowerCase();
        const filterId = event.target.id;
        this.setState((prev) => ({
            filters: {
                ...prev.filters,
                [filterId]: rawFilter
            },
            filterPatterns: {
                ...prev.filterPatterns,
                [filterId]: createFilterPattern(rawFilter)
            }
        }));
    };

    private createSortableHeader(id: string, content: React.ReactNode, width?: string, last?: boolean, sortable = true, searchable = true) {
        const tableCellStyle: { [key: string]: any } = {
            color: "black",
            // padding: this.props.isPage ? "auto" : 0,
            padding: 0,
            position: "sticky",
            top: 0,
            width: this.props.isPage ? "" : width
        };

        if (this.props.isPage)
            tableCellStyle["position"] = "sticky";

        return (
            <TableCell align="left" style={tableCellStyle}>
                <div className="ll_h_c">
                    <div className="ll_h_c">
                        <div className={marginLeft(4)}/>
                        <TableSortLabel
                            id={id} active={this.props.isPage && this.state.sortBy === id}
                            direction={this.state.sortOrder}
                            onClick={e => this.onSortClick(e, sortable)}>{content}</TableSortLabel>
                        {this.props.isPage && searchable && <div style={{height: "2rem"}}>
                            <Paper className="ll_h_c" style={{height: "2rem"}} elevation={1}>
                                <InputBase
                                    onChange={this.onFilterValueChange}
                                    value={this.state.filters[id]}
                                    id={id}
                                    style={{marginLeft: 8, fontSize: "100%"}}
                                    placeholder="Filter"/>
                                <Icon aria-label="Search" style={{paddingRight: "0.5rem"}}>
                                    <SearchIcon/>
                                </Icon>
                            </Paper>
                        </div>}
                    </div>
                </div>
            </TableCell>
        );
    }

    private renderRow = (message: DB_PushMessageAndEnv, idx: number) => {
        return <RenderRow key={message.mId} idx={idx} message={message} isPage={this.props.isPage} getEnv={this.getEnv}
                          unit={this.props.unit}/>
    };

    private getEnv = (message: DB_PushMessageAndEnv): string => {
        switch (message.env) {
            case "ir-q-kaspero-dev":
                return "dev";
            case "elliq-env-map-staging":
                return "staging";
            case "elliq-env-map":
                return "prod";
            default:
                return message.env;
        }
    };

    render() {
        const cssProps: CSSProperties = {
            overflow: "hidden",
            width: "100%",
            height: "100%"
        }

        return <UnitContext.Consumer>
            {units => {
                const {activatedUnits, pairedUnits} = units;

                return <div className={"flex-column"} style={this.props.isPage ? cssProps : {}}>
                    {this.props.isPage && this.renderLoading()}
                    {this.getTable([...Array.from(pairedUnits), ...Array.from(activatedUnits)])}
                </div>
            }}
        </UnitContext.Consumer>;
    }

    private getTable = (unitList: string[]) => {
        const sortedContent = this.getSortedAndFilteredList(unitList);
        // const tableHeight = this.props.isPage && this.props.tabHeight ? this.props.tabHeight - 36 : "unset";
        const start = this.state.page * this.maxPerPage;
        const paginatedContent = sortedContent.slice(start, start + this.maxPerPage)
        const cellHeaderFont = '0.7rem';
        return <div className="match_height stacking flex-column">
            {this.renderPagination(sortedContent)}
            <TableContainer component={Paper}>
                <Table className={"match_height overflow-auto"}>
                    <TableHead className={"table-header-color"}>
                        <TableRow className={this.props.isPage ? "" : "MuiTableRow-head-override"}>
                            {this.createSortableHeader("name", <div style={this.props.isPage ? {paddingLeft: 4} : {
                                paddingLeft: 4,
                                fontSize: cellHeaderFont
                            }}>Command name</div>)}
                            {this.createSortableHeader("timestamp", <div
                                style={this.props.isPage ? {} : {fontSize: cellHeaderFont}}>Timestamp sent</div>)}
                            {this.createSortableHeader("clientResultTimestamp", <div
                                style={this.props.isPage ? {} : {fontSize: cellHeaderFont}}>Result client timestamp</div>)}
                            {this.props.isPage && this.createSortableHeader("appFrom", <div>From app</div>)}
                            {this.createSortableHeader("status", <div
                                style={this.props.isPage ? {} : {fontSize: cellHeaderFont}}>Status</div>)}
                            {this.createSortableHeader("receiverApp", <div
                                style={this.props.isPage ? {} : {fontSize: cellHeaderFont}}>Receiver app</div>)}
                            {this.props.isPage && this.createSortableHeader("device", <div>Device</div>)}
                            {this.createSortableHeader("author", <div
                                style={this.props.isPage ? {} : {fontSize: cellHeaderFont}}>Author</div>)}
                            {this.createSortableHeader("push-env", <div
                                style={this.props.isPage ? {} : {fontSize: cellHeaderFont}}>Push
                                Env</div>, "5%", false, true, false)}
                            {this.createSortableHeader("actions", <div
                                key="actions"
                                className={`${padding(5)}`}
                                style={{width: 30, height: 30}}
                            />, "9%", false, false, false)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedContent.map(this.renderRow)}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                !this.props.isPage &&
                this.renderFromSupportFilter()
            }
        </div>
    };

    private getSortedAndFilteredList = (unitList: string[]): DB_PushMessageAndEnv[] => {
        const {filters, filterPatterns} = this.state;

        return this.props.content.filter((message) => {
            let commandName: string = "";
            if (message.data) {
                try {
                    commandName = JSON.parse((message.data as { [key: string]: any })["message"])["type"];
                } catch (e) {
                    commandName = "";
                }
            }

            const name = filters.name[0] !== "!" ?
                filterPatterns.name.test(commandName) :
                !(filterPatterns.name.test(commandName));

            const timestamp = filters.timestamp[0] !== "!" ?
                filterPatterns.timestamp.test(moment(message.timestamp).format("DD-MM-YY hh:mm:ss A")) :
                !filterPatterns.timestamp.test(moment(message.timestamp).format("DD-MM-YY hh:mm:ss A"));

            const appFrom = !message.proxyId ? true : filters.appFrom[0] !== "!" ?
                (message.proxyId && filterPatterns.appFrom.test(message.proxyId)) :
                (message.proxyId && !filterPatterns.appFrom.test(message.proxyId));

            const receiverApp = filters.receiverApp[0] !== "!" ?
                filterPatterns.receiverApp.test((message.data as { [key: string]: any })["packageName"]) :
                !filterPatterns.receiverApp.test((message.data as { [key: string]: any })["packageName"]);

            const status = filters.status[0] !== "!" ?
                (message.pushResult?.result && filterPatterns.status.test(message.pushResult?.result)) :
                (message.pushResult?.result && !filterPatterns.status.test(message.pushResult?.result));

            const deviceType = UnitsManagerModule.getDeviceType(message.deviceId, unitList);
            const device = filters.device[0] !== "!" ?
                (deviceType && filterPatterns.device.test(deviceType)) :
                (deviceType && !filterPatterns.device.test(deviceType));

            let author = true;
            if (filters.author) {
                author = !!message._audit && filterPatterns.author.test(message._audit?.auditBy);
                if (filters.author[0] === "!")
                    author = !!message._audit && !filterPatterns.author.test(message._audit?.auditBy);
            }

            return name && timestamp && appFrom && receiverApp && status && device && author;
        }).sort(
            (a: DB_PushMessageAndEnv, b: DB_PushMessageAndEnv) => {
                const priorityList: string[] = localStorage.getItem(PINNED_PUSH_COMMANDS_IDS)
                    ? JSON.parse(localStorage.getItem(PINNED_PUSH_COMMANDS_IDS) as string)
                    : [];

                const aIsPriority = priorityList.includes(a.mId);
                const bIsPriority = priorityList.includes(b.mId);

                // If one is a priority and the other isn't, prioritize the priority item
                if (aIsPriority && !bIsPriority) return -1;
                if (!aIsPriority && bIsPriority) return 1;

                // Otherwise, apply the normal sorting logic
                return (
                    (this.getSortMapper(unitList, this.state.sortBy)(a) > this.getSortMapper(unitList, this.state.sortBy)(b) ? -1 : 1) *
                    (this.state.sortOrder === SortOrder.ASC ? 1 : -1)
                );
            }
        ).slice(0, this.props.count);
    };

    private renderLoading() {
        if (!this.state.loading)
            return;

        return <SimpleLoader absolute={true}/>
    }

    private getSortMapper(unitList: string[], sortBy?: string) {
        switch (sortBy) {
            case "name":
                return (item: DB_PushMessage) => {

                    if (item.data) {
                        try {
                            return JSON.parse((item.data as { [key: string]: any })["message"])["type"];
                        } catch (e) {
                            return "";
                        }
                    }
                };

            case "timestamp":
                return (item: DB_PushMessage) => {
                    return item.timestamp;
                };

            case "clientResultTimestamp":
                return (item: DB_PushMessage) => {
                    if (item.pushResult?.clientTimestamp)
                        return item.pushResult.clientTimestamp;
                    return item.timestamp;
                }

            case "appFrom":
                return (item: DB_PushMessage) => {
                    return item.proxyId;
                };

            case "status":
                return (item: DB_PushMessage) => {
                    return item.pushResult?.result;
                };

            case "receiverApp":
                return (item: DB_PushMessage) => {
                    return (item.data as { [key: string]: any })["packageName"];
                };

            case "device":
                return (item: DB_PushMessage) => {
                    return UnitsManagerModule.getDeviceType(item.deviceId, unitList);
                };

            case "author":
                return (item: DB_PushMessage) => {
                    return item._audit?.auditBy;
                };

            case "push-env":
                return (item: DB_PushMessageAndEnv) => {
                    return this.getEnv(item);
                };

            default:
                throw new ImplementationMissingException(`No such type: ${sortBy}`);
        }
    }

    private renderPagination(sortedContent: DB_PushMessageAndEnv[]) {
        const {isPage} = this.props
        if (!isPage)
            return;

        const boxes = Math.ceil(sortedContent.length / this.maxPerPage);
        return <div className={`match_width ${padding(5)}`}
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {'Page: '}
                {Array.from({length: boxes}, (_, i) => {
                    return <div
                        key={"pagination_push_"+i}
                        className={this.getPaginationClass(i)}
                        onClick={() => this.setState(state => {
                            if (state.page === i)
                                return state

                            return {...state, page: i}
                        })}
                    >
                        {i + 1}
                    </div>
                })}
            </div>
            <div>
                {this.renderFromSupportFilter()}
            </div>
        </div>;
    }

    private getPaginationClass = (i: number) => {
        return css(
            {
                marginRight: 2,
                marginLeft: 2,
                color: this.state.page === i ? 'white' : "black",
                backgroundColor: this.state.page === i ? 'black' : "white",
                padding: 3,
                border: '1px solid',
                borderRadius: 8,
                cursor: 'pointer'
            });
    };

    toggleFromSupportFilter = () => {
        const previousFilter = this.state.filters['appFrom'];
        let newFilter = "";
        if (previousFilter === "") {
            newFilter = "ir-q-support";
        }

        this.setState((prev) => {
            localStorage.setItem(localStorageKey, String(!prev.showAllPushMessages));
            return {
                filters: {
                    ...prev.filters,
                    appFrom: newFilter
                },
                filterPatterns: {
                    ...prev.filterPatterns,
                    appFrom: createFilterPattern(newFilter)
                },
                showAllPushMessages: !prev.showAllPushMessages
            }
        });
    }
    private renderFromSupportFilter = () => {
        return <div style={{
            paddingLeft: 8, marginLeft: 4, color: "#3f51b5",
            display: "flex",
            height: 35

        }}>
            <FormControlLabel
                control={
                    <Switch
                        color={"primary"}
                        checked={this.state.showAllPushMessages}
                        onChange={this.toggleFromSupportFilter}
                        value="checkedA"
                    />
                }
                label="Show all push messages"
            />
        </div>
    }
}


type RowProps = {
    message: DB_PushMessageAndEnv,
    isPage: boolean
    getEnv: (message: DB_PushMessageAndEnv) => string
    unit: Unit
    idx: number
};

class RenderRow
    extends BaseComponent<RowProps, { loading: boolean }>
    implements OnCancelPush, OnRedoPush {

    constructor(props: RowProps) {
        super(props);
        this.state = {
            loading: false
        }
    }

    __onRedo(mId?: string) {
        if (this.props.message.mId !== mId)
            return;

        this.setState({loading: false})
    }

    __onCancelPush(mId: string, loading = false) {
        if (this.props.message.mId !== mId)
            return;

        this.setState({loading})
    }

    render() {
        const {loading} = this.state
        const {message, isPage, idx} = this.props
        let commandName: string = "";
        let messageDataType: { [key: string]: any } = {};
        if (message.data) {
            try {
                messageDataType = JSON.parse((message.data as { [key: string]: any })["message"]);
                commandName = messageDataType["type"];
            } catch (e) {
                messageDataType = {"data": message.data};
                commandName = "";
            }
        }

        const rowKey = message.mId + idx;
        const myClass = this.getRowClass(loading, message.pushResult?.result);
        const cellFontSize = '0.7rem';
        const audit = message._audit?.auditBy.split("@")[0];
        return <TableRow aria-disabled={loading} key={rowKey} className={myClass}>
            <TableCell
                style={isPage ? {padding: "0 10px"} : {padding: 0, paddingLeft: 7, fontSize: cellFontSize}}
                component="th"
                scope="row">
                <div onMouseMove={(e: React.MouseEvent) => {
                    const content = <table>
                        <thead>
                        <tr>
                            {Object.keys(messageDataType).map((header, i) => <th
                                key={rowKey + i}>{header}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>{this.renderTooltipRow(messageDataType, rowKey)}</tr>
                        </tbody>
                    </table>;
                    showTooltip(e, content, e.clientY, {
                        padding: 10,
                        backgroundColor: "white",
                        border: `1px solid light-blue`
                    })
                }}
                     onMouseLeave={() => this.hideTooltip()}>
                    {commandName}
                </div>
            </TableCell>
            <TableCell
                style={isPage ? {padding: "0 10px"} : {padding: 0, paddingLeft: 7, fontSize: cellFontSize}}
                component="th"
                scope="row">
                {moment(message.timestamp).format("ddd DD MMM, HH:mm:ss")}
            </TableCell>
            <TableCell
                style={isPage ? {padding: "0 10px"} : {padding: 0, paddingLeft: 7, fontSize: cellFontSize}}
                component="th"
                scope="row">
                {message.pushResult?.clientTimestamp ? moment(message.pushResult.clientTimestamp).format("ddd DD MMM, HH:mm:ss") : ""}
            </TableCell>
            {isPage && <TableCell
                style={{padding: "0 10px"}}
                component="th"
                scope="row">
                {message?.proxyId}
            </TableCell>}
            <TableCell
                style={isPage ? {padding: "0 10px"} : {padding: 0, paddingLeft: 7, fontSize: cellFontSize}}
                component="th"
                scope="row">
                <div onMouseMove={(e: React.MouseEvent) => {
                    const result: {
                        [key: string]: any
                    } | undefined = message.pushResult && cloneObj(message.pushResult);
                    if (result && result["serverTimestamp"])
                        result["serverTimestamp"] = moment(result["serverTimestamp"]).format("DD-MM-YY hh:mm:ss A");

                    if (result && result["clientTimestamp"])
                        result["clientTimestamp"] = moment(result["clientTimestamp"]).format("DD-MM-YY hh:mm:ss A");

                    const content = result && (<table
                        className={css(`
								th, td {
                  border: 1px solid black;
								}
							`)}
                    >
                        <thead>
                        <tr>{Object.keys(result).map(header => <th
                            key={`${rowKey}${header}timestamps`}>{header}</th>)}</tr>
                        </thead>
                        <tbody>
                        <tr>{this.renderTooltipRow(result, rowKey)}</tr>
                        </tbody>
                    </table>);
                    showTooltip(e, content, e.clientY, {
                        padding: 10,
                        backgroundColor: "white",
                        border: `1px solid light-blue`
                    })
                }}
                     onMouseLeave={() => this.hideTooltip()}>
                    {this.createLinkIfNeeded(message)}
                </div>
            </TableCell>

            <TableCell
                style={isPage ? {padding: "0 10px"} : {
                    padding: 0,
                    paddingLeft: 7,
                    overflowWrap: "break-word",
                    fontSize: cellFontSize
                }}
                component="th"
                scope="row">
                {this.receiverAppNameResolver(message)}
            </TableCell>

            {isPage && <TableCell
                style={isPage ? {padding: "0 10px"} : {padding: 0, paddingLeft: 7}}
                component="th" scope="row">
                {UnitsManagerModule.getDeviceType(message.deviceId, [this.props.unit.unitId])}
                {/*{message.deviceId}*/}
            </TableCell>}

            <TableCell
                style={isPage ? {padding: "0 10px"} : {padding: 0, paddingLeft: 7, fontSize: cellFontSize}}
                component="th"
                scope="row"
            >
                <div
                    style={{overflowWrap: "break-word"}}
                    onMouseMove={e => showTooltip(e, message._audit?.auditBy, e.clientY)}
                    onMouseLeave={this.hideTooltip}>
                    {audit}
                </div>
            </TableCell>
            <TableCell
                style={isPage ? {padding: "0 10px"} : {padding: 0, paddingLeft: 7, fontSize: cellFontSize}}
                component="th"
                scope="row"
                key={"push-env"}>
                {this.props.getEnv(message)}
            </TableCell>
            <TableCell
                style={{padding: "0 10px"}}
                component="th" scope="row" key={"actions"}>
                {this.getRowActions(message, messageDataType)}
            </TableCell>
        </TableRow>
    }


    private getRowClass(loading: boolean, result?: ActionResult) {
        const bgColor = this.rowColor(result);
        const loadingColor = '#c6c6c6'
        return css`
            height: 35px !important;
            background: ${loading ? loadingColor : bgColor};

            :hover {
                background: ${loading ? loadingColor : bgColor + '30'};
            }
        `
    }

    private getRowActions = (message: DB_PushMessageAndEnv, parsed: { [key: string]: any }) => {
        if (this.state.loading)
            return <div className={spinnerCall}/>

        const result = message.pushResult?.result;
        const couldCancel = result === ActionResult.Queued || result === ActionResult.Sent;
        let textToCopy: string | undefined = JSON.stringify({result: message.pushResult, message: parsed});
        if (result === ActionResult.Success && parsed["type"] === "enable-wifi-adb")
            textToCopy = message.pushResult.output?.replace(" - Updated via spinta", "");

        return <div className={'ll_h_c'}>
            <img
                alt={couldCancel ? 'Cancel' : 'Redo'}
                src={require(`@res/images/${couldCancel ? 'icon__x.svg' : 'icon__redo.png'}`)}
                width={18}
                className={"clickable"}
                onClick={() => {
                    if (couldCancel)
                        PushMessagesModule.cancelPushMessage(this.props.unit.unitId, this.props.unit.product, message);
                    else
                        PushMessagesModule.redoPush(message, this.props.unit.unitId);

                    return this.setState({loading: true})
                }}
            />
            <CopyButton size={24} obj={textToCopy} tooltipContent={"Copied push info and result"}/>
            <PinButton size={24} obj={message} tooltipContent={"Pin push command"} onStart={() => this.setState({loading: true})} onDone={() => this.setState({loading: false})}/>
        </div>
    };

    private receiverAppNameResolver = (message: DB_PushMessageAndEnv) => {
        const app = PackageManagerModule.getApps().find(_app => _app.packageName === message.data.packageName);
        if (app && app.name)
            return app.name;
        return message.data.packageName
    }


    private hideTooltip = () => TooltipModule.hide();

    private parseUrlFromString = (input: string | undefined): string | null => {
        if (input === undefined)
            return null;

        const urlPattern = /https?:\/\/[^\s]+/g;
        const match = input.match(urlPattern);
        return match ? match[0] : null;
    }

    private createLinkIfNeeded = (message: DB_PushMessageAndEnv): React.ReactNode => {
        const url: string | null = this.parseUrlFromString(message.pushResult.output);
        if (url !== null)
            return <a href={url} target="_blank">{message.pushResult?.result}</a>
        return <span>{message.pushResult?.result}</span>;
    }

    private rowColor = (status?: ActionResult) => {
        switch (status) {
            case ActionResult.Queued:
                return COLOR_purple;
            case ActionResult.Sent:
                return COLOR_lightSkyBlue
            case ActionResult.Read:
                return COLOR_lightYellow;
            case ActionResult.Success:
            case ActionResult.Processed:
                return COLOR_lightGreen;
            case ActionResult.Error:
                return COLOR_lightRed;
            case ActionResult.Cancelled:
                return COLOR_orange;
            case ActionResult.Expired:
                return COLOR_lightOrange;
            default:
                return COLOR_iceBlue;
        }
    };

    private renderTooltipRow(messageDataType: { [p: string]: any }, rowKey: string) {
        return Object.values(messageDataType).map((value, i) => {
            const key = rowKey + i + "value";
            if (typeof value === "string")
                return <th key={key}>{value}</th>;

            return <th key={key}>{__stringify(value, true)}</th>;
        });
    }
}
